<template>
  <div v-if="selectableGlazingBars.total > 0" class="mb-8">
    <section-header :item-type="itemType" />
    <div class="px-3 py-3">
      <v-row>
        <v-col>
          <div v-html="$store.getters['productData/descriptionShortMenu'](itemType)" style="font-size: 14px"></div>
        </v-col>
      </v-row>
    </div>
    <v-btn-toggle
      v-model="showOptional"
      mandatory
      style="width: 100%"
      color="primary"
      class="d-flex mt-0 mb-10"
    >
      <v-btn
        :value="false"
        @click="toggleOptionalConfig(false)"
        :class="{'active-btn': !optionalConfigActive, 'inactive-btn': optionalConfigActive}"
        class="flex-grow-1"
      >
        {{ $t('optional.skip') }}
        <v-icon v-if="!optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
      <v-btn
        :value="true"
        @click="toggleOptionalConfig(true)"
        :class="{'active-btn': optionalConfigActive, 'inactive-btn': !optionalConfigActive}"
        class="flex-grow-1"
      >
        {{ $t('optional.enable') }}
        <v-icon v-if="optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div v-if="showOptional">
      <div>
        <div
          v-for="(items, key, index) in groupedGlazingBars"
          :key="index"
          class="item-row"
        >
          <div v-if="key !== 'undefined'">
            <h3 class="text-caption">{{ key }}</h3>
            <v-divider class="mb-3 mt-1"></v-divider>
          </div>
          <v-row>
            <v-col
              cols="12"
              md="6"
              :lg="columnWidth(columns)"
              v-for="item in items"
              :key="item.id"
            >
              <v-slide-y-transition>
                <selectionCard :item="item" :item-type="itemType" />
              </v-slide-y-transition>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" class="d-flex justify-end" style="padding-top: 0">
            <v-switch
              v-if="selectableGlazingBars.total > maxItems"
              v-model="showAll"
              :label="$t('defaultTexts.showAll')"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>
        <div v-if="customerConfig.glazing_bars">
          <v-divider></v-divider>
          <h3 class="mt-3 mb-5 font-weight-light" id="glazing_bars_divisions">
            {{ $t("glazing_bars_divisions.heading") }}
          </h3>
          <v-row class="fill-height" align="center">
            <v-col cols="12" lg="6">
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  class="py-0"
                  v-for="(_, index) in windowSectionWidths.length"
                  :key="index"
                >
                  <v-select
                    v-model="glazing_bars_divisions[index]"
                    :items="selectableGlazingBarsDivisions.items"
                    color="primary"
                    :label="`${$t('dimension.segment')} ${index + 1}`"
                    disable-lookup
                    hide-selected
                    return-object
                    item-text="label"
                    @change="changeGlazingBarsDivisions"
                    clearable
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <img :src="imageURL(data.item.image)" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-subtitle
                            v-text="data.item.label"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6" class="d-flex justify-center">
              <window
                class="mt-1 mb-1"
                :id="`window_${Math.round(Math.random() * 100000000)}`"
                :svg-max-height="200"
                :svg-max-width="400"
                :image-url="decorUrl()"
                :opening-code="
                  customerConfig.openings
                    ? customerConfig.openings.opening_code
                    : ''
                "
                :opening-min-width="
                  customerConfig.openings
                    ? customerConfig.openings.min_width
                    : 200
                "
                :opening-max-width="
                  customerConfig.openings
                    ? customerConfig.openings.max_width
                    : 400
                "
                :opening-min-height="
                  customerConfig.openings
                    ? customerConfig.openings.min_height
                    : 200
                "
                :opening-max-height="
                  customerConfig.openings
                    ? customerConfig.openings.max_height
                    : 400
                "
                :splitting="
                  customerConfig.dimensions
                    ? customerConfig.dimensions.splitting
                    : []
                "
                :total-width="
                  customerConfig.dimensions
                    ? customerConfig.dimensions.total_width
                    : 1000
                "
                :total-height="
                  customerConfig.dimensions
                    ? customerConfig.dimensions.total_height
                    : 1000
                "
                :use-textures="true"
                handle-color="#FFFFFF"
                show-segment-numbers
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mainMixin } from "@/mixins/mainMixin";
import SectionHeader from "@/components/SectionHeader";
import SelectionCard from "@/components/SelectionCard";
import Window from "@/components/Window";

export default {
  name: "GlazingBars",
  components: { SelectionCard, SectionHeader, Window },
  props: {
    maxItems: {
      type: [Number, String],
      default: 100,
    },
    itemType: {
      type: String,
      required: true,
    },
    item: {},
    columns: {
      type: Number,
      default: 3,
    },
  },
  mixins: [mainMixin],
  data() {
    return {
      showAll: false,
      showOptional: false,
      optionalConfigActive: null
    };
  },
  computed: {
    // we want to be able to conditionally show only x items at first
    // but be able to have the customer - via switch - to show all items
    selectableGlazingBars() {
      return {
        total: this?.item?.items?.length,
        items: this.showAll
          ? this?.item?.items
          : this?.item?.items?.slice(0, this.maxItems),
      };
    },
    // some items my be categorized by a group_name property.
    // so we oughta group the selectable items by this group name
    groupedGlazingBars() {
      return this.groupArrayBy(
        this?.selectableGlazingBars?.items,
        (item) => item?.group_name
      );
    },
    // this component is non standard as we also show the glazing bars divisions
    // hence we need to include them in here as well
    selectableGlazingBarsDivisions() {
      return {
        total:
          this.$store.state.productData.model?.glazing_bars_divisions?.items
            ?.length,
        items:
          this.$store.state.productData.model?.glazing_bars_divisions?.items,
      };
    },
    glazing_bars_divisions: {
      get() {
        return this.customerConfig.glazing_bars_divisions;
      },
      set(value) {
        return (this.customerConfig.glazing_bars_divisions = value);
      },
    },
    customerConfig() {
      return this.$store.state.configuration.customerConfig;
    },
    windowSectionWidths() {
      return this.customerConfig.dimensions.splitting
        .map((s) => s.section_widths)
        .flat();
    },
  },
  methods: {
    async toggleOptionalConfig(value) {
      this.optionalConfigActive = value;
      const payload = {
        itemType: this.itemType,
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload
      );
      /*// glazing bars divisions
      const payload_glazing_bars_divisions = {
        itemType: "glazing_bars_divisions",
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload_glazing_bars_divisions
      );*/
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );
    },
    async changeGlazingBarsDivisions() {
      this.$root.$emit("sectionChanged");
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );
    },
  },
  watch: {
    "item.items": {
      handler() {
        // this.showAll = false;
      },
    },
    showAll: {
      handler() {
        const payload = {
          itemType: this.itemType,
          maxItems: this.maxItems,
        };
        this.$store.dispatch("productData/moveItemToStayVisible", payload);
      },
    },
  },
};
</script>
<style scoped>

/deep/ .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn.active-btn {
  border: 2px solid #F59100 !important;
  background-color: white !important;
  border-radius: 4px !important;
}

.inactive-btn {

}

.letwork-card-icon {
  position: absolute !important;
  right: -20px;
  top: -23px;
  border-radius: 2px;
  padding: 2px;
  background-color: #F59100;
  font-size: 25px;
  color: white !important;
  z-index: 2;
}

.item-row {
  margin-bottom: 2rem;
}
</style>
