<template>
  <v-row justify="center">
    <v-col :id="id" cols="auto"></v-col>
    <div class="col-12 text-center" style="font-size: 11px; margin-top: -5px; padding: 0 !important;"> {{ $t("configuration.insideView") }}</div>
  </v-row>
</template>

<script>
import { mainMixin } from "@/mixins/mainMixin";

export default {
  name: "Window",
  props: {
    id: {
      type: String,
      required: true
    },
    svgMaxWidth: {
      type: [Number, String],
      default: 200
    },
    svgMaxHeight: {
      type: [Number, String],
      default: 200
    },
    openingCode: {
      type: String,
      required: true
    },
    openingMinWidth: {
      type: [Number, String],
      required: true
    },
    openingMaxWidth: {
      type: [Number, String],
      required: true
    },
    openingMinHeight: {
      type: [Number, String],
      required: true
    },
    openingMaxHeight: {
      type: [Number, String],
      required: true
    },
    openingLinesColor: {
      type: String,
      default: "#6a7380"
    },
    openingLinesWidth: {
      type: Number,
      default: 0.5
    },
    blendRahmenBreite: {
      type: [Number, String],
      default: 37
    },
    blendRahmenBreiteBeiFestverglasung: {
      type: [Number, String],
      default: 45
    },
    fluegelRahmenBreite: {
      type: [Number, String],
      default: 57
    },
    pfostenBreiteMitFluegel: {
      type: [Number, String],
      default: 20
    },
    pfostenBreiteOhneFluegel: {
      type: [Number, String],
      default: 45
    },
    pfostenBreiteMitFluegelZuFest: {
      type: [Number, String],
      default: 30
    },
    glasLeiste: {
      type: [Number, String],
      default: 18
    },
    glasLeisteStrokeInnen: {
      type: [Number, String],
      default: "#afafaf"
    },
    totalWidth: {
      type: [Number, String],
      default: 200
    },
    totalHeight: {
      type: [Number, String],
      default: 200
    },
    imageUrl: {
      type: String,
      default: undefined
    },
    useTextures: {
      type: Boolean,
      default: false
    },
    useInputFields: {
      type: Boolean,
      default: false
    },
    showSegmentNumbers: {
      type: Boolean,
      default: false
    },
    showConnectingLines: {
      type: Boolean,
      default: true
    },
    showInfosInSegment: {
      type: Boolean,
      default: true
    },
    handleWidth: {
      type: [Number, String],
      default: 30
    },
    handleHeight: {
      type: [Number, String],
      default: 80
    },
    handleBarWidth: {
      type: [Number, String],
      default: 20
    },
    handleBarHeight: {
      type: [Number, String],
      default: 120
    },
    handleColor: {
      type: String,
      default: "#FFFFFF"
    },
    glazingBarWidth_H: {
      type: [Number, String],
      default: 100
    },
    glazingBarHeight_H: {
      type: [Number, String],
      default: 20
    },
    glazingBarColor: {
      type: String,
      default: "#FFFFFF"
    },
    splitting: {
      type: Array,
      required: true
    }
  },
  mixins: [mainMixin],
  data() {
    return {
      strokeWidth: 0.75,
      windowElement: undefined,
      canvas: undefined,
      windowGroup: undefined,
      spaceTop: 25,
      spaceRight: 0,
      spaceBottom: 0,
      spaceLeft: 25,
      moveX: 0,
      moveXInner: 0,
      moveXInner_GlazingBar: 0,
      moveYInner_GlazingBar: 0,
      moveXHandle: 0,
      moveXHandleBar: 0,
      moveY: 0,
      moveYInner: 0,
      moveYHandle: 0,
      moveYHandleBar: 0,
      segmentCounter: 0,
      oCCache: "",
      windowsill_connection_color: "#e6e6e6",
      measure_line_color: "#ed651a",
      measure_line_thickness: 0.5,
      measure_line_space: 20,
      measure_number_space: 40,
      measure_number_size: 12,
      measure_number_font: "Arial",
      segment_color: "#c1cff5",
      segment_number_width: 200,
      segment_number_font: "Arial",
      connecting_line_color: "#858585",
      connecting_line_thickness: 0.5,
      info_color: "#5b6473",
      info_size: 12,
      info_font: "Arial",
      ornament_glazing_color: "#efdbd3"
    };
  },
  updated() {
    // this.$nextTick(function () {
    //   this.cleanCanvas(this.id);
    // });
  },
  computed: {
    divisions() {
      return this.openingCode?.split("-")?.length;
    },
    totals() {
      return {
        width: this.totalWidth / this.multiplier(),
        height: this.totalHeight / this.multiplier()
      };
    },
    dimensions() {
      return this.$store.state.configuration.customerConfig?.dimensions ?? 1200;
    },
    windowsill_connection() {
      return this.$store.state.configuration.customerConfig?.windowsill_connections?.default_height ?? 0;
    },
    frame_ext_above() {
      return this.$store.state.configuration.customerConfig?.frame_extension_above?.default_height ?? 0;
    },
    frame_ext_below() {
      return this.$store.state.configuration.customerConfig?.frame_extension_below?.default_height ?? 0;
    },
    frame_ext_left() {
      return this.$store.state.configuration.customerConfig?.frame_extension_left?.default_height ?? 0;
    },
    frame_ext_right() {
      return this.$store.state.configuration.customerConfig?.frame_extension_right?.default_height ?? 0;
    },
    svgStroke() {
      return {
        color: this.$vuetify.theme.themes[this.theme].windowStroke,
        width: this.strokeWidth
      };
    },
    svgStrokeGlasLeiste() {
      return {
        color: this.glasLeisteStrokeInnen,
        width: this.strokeWidth
      };
    },
    rahmenTextur() {
      //return this.useTextures
      //? this.imageUrl
      //: this.$vuetify.theme.themes[this.theme].windowFill;
      return this.$vuetify.theme.themes[this.theme].windowFill;
    },
    glazingBars() {
      return this.$store.state.configuration.customerConfig.glazing_bars;
    },
    glazingBarsDivision(sectionNumber) {
      //console.log(sectionNumber);
      return this.$store.state.configuration.customerConfig
        .glazing_bars_divisions[sectionNumber];
    }
  },
  methods: {
    hatRahmenVerglasung(openingCodeSection) {
      return this.countElements(openingCodeSection.split(""), "F") === 1;
    },
    isF(openingCodeSection) {
      return this.countElements(openingCodeSection.split(""), "F") === 1;
    },
    hasHandle(openingCodeSection) {
      return !openingCodeSection.startsWith("F");
    },
    canTilt(openingCodeSection) {
      return openingCodeSection.includes("K");
    },
    isStulp(openingCodeSection) {
      return openingCodeSection.includes("DS");
    },
    isPSK(openingCodeSection) {
      return openingCodeSection.includes("PSK");
    },
    isHSTL(openingCodeSection) {
      return openingCodeSection.includes("HSTL");
    },
    isHSTR(openingCodeSection) {
      return openingCodeSection.includes("HSTR");
    },
    isHSTF(openingCodeSection) {
      return openingCodeSection.includes("HSTF");
    },
    canTurn(openingCodeSection) {
      return openingCodeSection.includes("D");
    },
    handlePosition(openingCodeSection) {
      let position;
      switch (openingCodeSection.slice(-1)) {
        case "L":
          position = "right";
          if (this.isPSK(openingCodeSection)) {
            position = "left";
          }
          break;
        case "R":
          position = "left";
          if (this.isPSK(openingCodeSection)) {
            position = "right";
          }
          break;
        default:
          position = undefined;
      }
      return position;
    },
    cleanCanvas(id) {
      this.windowElement = document.getElementById(id);
      if (this.windowElement) this.windowElement.innerHTML = "";
      this.canvas = undefined;
    },
    drawWindow() {
      try {

        let add_more_height = 0;
        if (this.splitting[0]?.section_widths.length > 1 || this.splitting[1]?.section_widths.length > 1) {
          add_more_height = this.spaceTop;
        } else if (this.windowsill_connection > 0) {
          add_more_height = 10;
        }

        let add_more_width = 0;
        if (this.splitting.length > 1) {
          add_more_width = this.spaceLeft;
        }

        let add_more_height_for_windowsill = 0;
        if (this.windowsill_connection > 0) {
          add_more_width = this.spaceLeft + 15;
          add_more_height_for_windowsill = this.windowsill_connection / this.multiplier();
        }

        let frame_ext_y = 0;
        let add_more_height_for_frame_ext_above = 0;
        if (this.frame_ext_above > 0) {
          frame_ext_y = this.spaceTop;
          add_more_height_for_frame_ext_above = this.frame_ext_above / this.multiplier();
        }

        let measure_y_bottom = 0;
        let add_more_height_for_frame_ext_below = 0;
        if (this.frame_ext_below > 0) {
          measure_y_bottom = this.frame_ext_below / this.multiplier();
          add_more_height_for_frame_ext_below = this.frame_ext_below / this.multiplier() + 18;
          if (this.windowsill_connection < 60) {
            add_more_height_for_windowsill = 60 / this.multiplier();
          }
        }

        let measure_x_left = 0;
        if (this.frame_ext_left > 0) {
          measure_x_left = this.frame_ext_left / this.multiplier();
        }

        let measure_x_right = 0;
        if (this.frame_ext_right > 0) {
          measure_x_right = this.frame_ext_right / this.multiplier();
          add_more_width = this.spaceLeft + 15;
        }

        this.canvas = this.$svg(this.id).size(
          this.totals.width + (this.spaceLeft + this.spaceRight) + 1 + add_more_width + measure_x_left + measure_x_right,
          this.totals.height + (this.spaceTop + this.spaceBottom) + 1 + add_more_height + add_more_height_for_windowsill + add_more_height_for_frame_ext_above + add_more_height_for_frame_ext_below
        );

        this.windowGroup = this.canvas.group();

        // set the starting point to 0
        this.moveX = this.spaceLeft + measure_x_left;
        this.moveXInner = measure_x_left;
        this.moveY = this.spaceTop + add_more_height_for_frame_ext_above;
        this.moveYInner = 0;
        this.segmentCounter = 0;

        /********************************************************************************************************************************************************* measure lines left ******** beginning ********/

        let total_measure_h_line_top = this.canvas
          .line(0, this.moveY, this.measure_line_space, this.moveY)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_h_line_top);

        let total_measure_h_line_bottom = this.canvas
          .line(0, this.moveY + this.totals.height, this.measure_line_space, this.moveY + this.totals.height)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_h_line_bottom);

        let total_measure_v_line_top_to_bottom = this.canvas
          .line(this.measure_line_space / 2, this.moveY, this.measure_line_space / 2, this.moveY + this.totals.height)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_v_line_top_to_bottom);

        let total_measure_height_background_rect = this.canvas
          .rect(this.measure_line_space, this.measure_number_space)
          .fill("#FFFFFF")
          .move(0, this.moveY + (this.totals.height / 2) - (this.measure_number_space / 2));
        this.windowGroup.add(total_measure_height_background_rect);

        let total_measure_height = this.canvas.text(this.totalHeight + " ").move(this.measure_line_space / 2.5, this.moveY + (this.totals.height / 2) - 5).font({
          fill: this.measure_line_color,
          size: this.measure_number_size,
          family: this.measure_number_font,
          anchor: "middle"
        }).rotate(270);

        this.windowGroup.add(total_measure_height);

        /********************************************************************************************************************************************************* measure lines left ********* ending *********/

        /********************************************************************************************************************************************************* measure lines top ******** beginning ********/

        let total_measure_v_line_left = this.canvas
          .line(this.moveX, 0, this.moveX, this.measure_line_space)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_v_line_left);

        let total_measure_v_line_right = this.canvas
          .line(this.moveX + this.totals.width, 0, this.moveX + this.totals.width, this.measure_line_space)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_v_line_right);

        let total_measure_h_line_left_to_right = this.canvas
          .line(this.moveX, this.measure_line_space / 2, this.moveX + this.totals.width, this.measure_line_space / 2)
          .stroke({
            color: this.measure_line_color,
            width: this.measure_line_thickness
          });
        this.windowGroup.add(total_measure_h_line_left_to_right);

        let total_measure_width_background_rect = this.canvas
          .rect(this.measure_number_space, this.measure_line_space)
          .fill("#FFFFFF")
          .move(this.moveX + (this.totals.width / 2) - (this.measure_number_space / 2), 0);
        this.windowGroup.add(total_measure_width_background_rect);

        let total_measure_width = this.canvas.text(this.totalWidth + " ").move(this.moveX + (this.totals.width / 2), this.measure_line_space / 4).font({
          fill: this.measure_line_color,
          size: this.measure_number_size,
          family: this.measure_number_font,
          anchor: "middle"
        });
        this.windowGroup.add(total_measure_width);

        /********************************************************************************************************************************************************* measure lines top ********* ending *********/

        /********************************************************************************************************************************************************* measure lines bottom ********* beginning *********/
        if (this.splitting[0]?.section_widths.length > 1 || this.splitting[1]?.section_widths.length > 1) {

          let space_to_top = this.moveY + this.totals.height + this.spaceBottom + (this.spaceTop - this.measure_line_space) + measure_y_bottom + add_more_height_for_windowsill;

          this.splitting.forEach((row, rowIndex) => {

            let segment_measure_v_line_left = this.canvas
              .line(this.moveX, space_to_top, this.moveX, space_to_top + this.measure_line_space)
              .stroke({
                color: this.measure_line_color,
                width: this.measure_line_thickness
              });
            this.windowGroup.add(segment_measure_v_line_left);


            let segment_move_x_left = this.moveX;
            let segment_move_x_right = this.moveX;

            row.section_widths.forEach((column, columnIndex) => {

              segment_move_x_right += column / this.multiplier();
              let column_width = column / this.multiplier();

              let segment_measure_v_line_right = this.canvas
                .line(segment_move_x_right, space_to_top, segment_move_x_right, space_to_top + this.measure_line_space)
                .stroke({
                  color: this.measure_line_color,
                  width: this.measure_line_thickness
                });

              this.windowGroup.add(segment_measure_v_line_right);

              let segment_measure_h_line_left_to_right = this.canvas
                .line(segment_move_x_left, space_to_top + this.measure_line_space / 2, segment_move_x_right, space_to_top + this.measure_line_space / 2)
                .stroke({
                  color: this.measure_line_color,
                  width: this.measure_line_thickness
                });
              this.windowGroup.add(segment_measure_h_line_left_to_right);

              let segment_measure_width_background_rect = this.canvas
                .rect(this.measure_number_space, this.measure_line_space)
                .fill("#FFFFFF")
                .move(segment_move_x_left + (column_width / 2) - (this.measure_number_space / 2), space_to_top);
              this.windowGroup.add(segment_measure_width_background_rect);

              let segment_measure_width = this.canvas.text(column + " ").move(segment_move_x_left + (column_width / 2), space_to_top + this.measure_line_space / 4).font({
                fill: this.measure_line_color,
                size: this.measure_number_size,
                family: this.measure_number_font,
                anchor: "middle"
              });
              this.windowGroup.add(segment_measure_width);

              if (this.useInputFields) {

                //this.segmentRect = segment_measure_width_background_rect.node;

                //const x = parseFloat(this.segmentRect.getAttribute('x'));
                //const y = parseFloat(this.segmentRect.getAttribute('y'));

                let input = document.getElementById("segment_width_" + rowIndex + "_" + columnIndex);

                if (input) {
                  input.style.position = "absolute";
                  input.style.left = `${segment_move_x_left}px !important`;
                  input.style.top = `${space_to_top}px !important`;
                  input.style.zIndex = "1000 !important";
                }
              }

              segment_move_x_left += column / this.multiplier();
            });

          });

        }
        /********************************************************************************************************************************************************* measure lines bottom ********* ending *********/

        /********************************************************************************************************************************************************* measure lines right ********* beginning *********/
        if (this.splitting.length > 1) {

          let space_to_right = this.moveX + this.totals.width + (this.spaceLeft - this.measure_line_space) + measure_x_right;
          let segment_move_y_top = this.moveY;
          let segment_move_y_bottom = this.moveY;

          this.splitting.forEach((row) => {

            let segment_measure_h_line_top = this.canvas
              .line(space_to_right, this.moveY, space_to_right + this.measure_line_space, this.moveY)
              .stroke({
                color: this.measure_line_color,
                width: this.measure_line_thickness
              });
            this.windowGroup.add(segment_measure_h_line_top);

            segment_move_y_bottom += row.height / this.multiplier();
            let row_height = row.height / this.multiplier();

            let segment_measure_h_line_bottom = this.canvas
              .line(space_to_right, segment_move_y_bottom, space_to_right + this.measure_line_space, segment_move_y_bottom)
              .stroke({
                color: this.measure_line_color,
                width: this.measure_line_thickness
              });
            this.windowGroup.add(segment_measure_h_line_bottom);

            let segment_measure_v_line_top_to_bottom = this.canvas
              .line(space_to_right + this.measure_line_space / 2, segment_move_y_top, space_to_right + this.measure_line_space / 2, segment_move_y_bottom)
              .stroke({
                color: this.measure_line_color,
                width: this.measure_line_thickness
              });
            this.windowGroup.add(segment_measure_v_line_top_to_bottom);

            let segment_measure_height_background_rect = this.canvas
              .rect(this.measure_line_space, this.measure_number_space)
              .fill("#FFFFFF")
              .move(space_to_right, segment_move_y_top + (row_height / 2) - (this.measure_number_space / 2));
            this.windowGroup.add(segment_measure_height_background_rect);

            let segment_measure_height = this.canvas.text(row.height + " ").move(space_to_right + this.measure_line_space / 2.5, segment_move_y_top + (row_height / 2) - 5).font({
              fill: this.measure_line_color,
              size: this.measure_number_size,
              family: this.measure_number_font,
              anchor: "middle"
            }).rotate(270);

            this.windowGroup.add(segment_measure_height);

            segment_move_y_top += row.height / this.multiplier();
          });
        }
        /********************************************************************************************************************************************************* measure lines right ********* ending *********/

        /********************************************************************************************************************************************************* baseWindow ********* beginning *********/
          // set the width and height to match the selected division
        let baseWindow = this.canvas
            .rect(this.totals.width, this.totals.height)
            .stroke(this.svgStroke)
            .fill(this.rahmenTextur)
            .move(this.moveX, this.moveY);

        this.windowGroup.add(baseWindow);

        /********************************************************************************************************************************************************* baseWindow ********* ending *********/


        /********************************************************************************************************************************************************* frame extensions left ********* beginning *********/

        let frame_ext_left_height = 0;
        if (this.frame_ext_left > 0) {

          frame_ext_left_height = this.frame_ext_left / this.multiplier();

          let frame_ext_left = this.canvas
            .rect(frame_ext_left_height, this.totals.height)
            .stroke(this.svgStroke)
            .fill(this.rahmenTextur)
            .move(this.moveX - frame_ext_left_height, this.moveY);

          this.windowGroup.add(frame_ext_left);

          let space_to_top = this.moveY + this.totals.height + this.spaceBottom + (this.spaceTop - this.measure_line_space) + measure_y_bottom + add_more_height_for_windowsill;

          let fe_left_measure_v_line_left = this.canvas
            .line(this.moveX - frame_ext_left_height, space_to_top, this.moveX - frame_ext_left_height, space_to_top + this.measure_line_space)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_left_measure_v_line_left);

          if (this.splitting[0]?.section_widths.length === 1 || this.splitting[0]?.section_widths.length === 1) {

            let fe_left_measure_v_line_right = this.canvas
              .line(this.moveX, space_to_top, this.moveX, space_to_top + this.measure_line_space)
              .stroke({
                color: this.measure_line_color,
                width: this.measure_line_thickness
              });
            this.windowGroup.add(fe_left_measure_v_line_right);

          }

          let fa_left_measure_h_line_left_to_right = this.canvas
            .line(this.moveX - frame_ext_left_height, space_to_top + this.measure_line_space / 2, this.moveX, space_to_top + this.measure_line_space / 2)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fa_left_measure_h_line_left_to_right);

          let fa_left_measure_width = this.canvas.text(this.frame_ext_left + " ").move(this.moveX - frame_ext_left_height - 10, space_to_top + this.measure_line_space / 4).font({
            fill: this.measure_line_color,
            size: this.measure_number_size,
            family: this.measure_number_font,
            anchor: "middle"
          });
          this.windowGroup.add(fa_left_measure_width);

        }

        /********************************************************************************************************************************************************* frame extensions left ********* ending *********/

        /********************************************************************************************************************************************************* frame extensions right ********* beginning *********/

        let frame_ext_right_height = 0;
        if (this.frame_ext_right > 0) {

          frame_ext_right_height = this.frame_ext_right / this.multiplier();

          let frame_ext_right = this.canvas
            .rect(frame_ext_right_height, this.totals.height)
            .stroke(this.svgStroke)
            .fill(this.rahmenTextur)
            .move(this.moveX + this.totals.width, this.moveY);

          this.windowGroup.add(frame_ext_right);

          let fe_right_measure_v_line_right = this.canvas
            .line(this.moveX + this.totals.width + frame_ext_right_height, 0, this.moveX + this.totals.width + frame_ext_right_height, this.measure_line_space)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_right_measure_v_line_right);

          let fa_right_measure_h_line_left_to_right = this.canvas
            .line(this.moveX + this.totals.width, this.measure_line_space / 2, this.moveX + this.totals.width + frame_ext_right_height, this.measure_line_space / 2)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fa_right_measure_h_line_left_to_right);

          let fa_right_measure_width = this.canvas.text(this.frame_ext_right + " ").move(this.moveX + this.totals.width + frame_ext_right_height + 10, this.measure_line_space / 4).font({
            fill: this.measure_line_color,
            size: this.measure_number_size,
            family: this.measure_number_font,
            anchor: "middle"
          });
          this.windowGroup.add(fa_right_measure_width);
        }

        /********************************************************************************************************************************************************* frame extensions right ********* ending *********/

        /********************************************************************************************************************************************************* frame extensions above ********* beginning *********/

        if (this.frame_ext_above > 0) {

          let frame_ext_above_height = this.frame_ext_above / this.multiplier();

          let frame_ext_above = this.canvas
            .rect(this.totals.width + frame_ext_left_height + frame_ext_right_height, frame_ext_above_height)
            .stroke(this.svgStroke)
            .fill(this.rahmenTextur)
            .move(this.moveX - frame_ext_left_height, frame_ext_y);

          this.windowGroup.add(frame_ext_above);

          let space_to_right = 0;

          let fe_above_measure_h_line_top = this.canvas
            .line(space_to_right, this.moveY - frame_ext_above_height, space_to_right + this.measure_line_space, this.moveY - frame_ext_above_height)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_above_measure_h_line_top);

          let fe_above_measure_v_line = this.canvas
            .line(this.measure_line_space / 2, this.moveY - frame_ext_above_height, this.measure_line_space / 2, this.moveY)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_above_measure_v_line);

          let fe_above_measure = this.canvas.text(this.frame_ext_above + " ").move(this.measure_line_space / 2.5, this.moveY - frame_ext_above_height - 18).font({
            fill: this.measure_line_color,
            size: this.measure_number_size,
            family: this.measure_number_font,
            anchor: "middle"
          }).rotate(270);

          this.windowGroup.add(fe_above_measure);

        }

        /********************************************************************************************************************************************************* frame extensions above ********* ending *********/

        /********************************************************************************************************************************************************* frame extensions below ********* beginning *********/

        if (this.frame_ext_below > 0) {

          let frame_ext_below_height = this.frame_ext_below / this.multiplier();

          let frame_ext_above = this.canvas
            .rect(this.totals.width + frame_ext_left_height + frame_ext_right_height, frame_ext_below_height)
            .stroke(this.svgStroke)
            .fill(this.rahmenTextur)
            .move(this.moveX - frame_ext_left_height, this.moveY + this.totals.height);

          this.windowGroup.add(frame_ext_above);

          let space_to_right = 0;

          let fe_below_measure_h_line_bottom = this.canvas
            .line(space_to_right, this.moveY + this.totals.height + frame_ext_below_height, space_to_right + this.measure_line_space, this.moveY + this.totals.height + frame_ext_below_height)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_below_measure_h_line_bottom);

          let fe_below_measure_v_line = this.canvas
            .line(this.measure_line_space / 2, this.moveY + this.totals.height, this.measure_line_space / 2, this.moveY + this.totals.height + frame_ext_below_height)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(fe_below_measure_v_line);

          let fe_below_measure = this.canvas.text(this.frame_ext_below + " ").move(this.measure_line_space / 2.5, this.moveY + this.totals.height + frame_ext_below_height + 4).font({
            fill: this.measure_line_color,
            size: this.measure_number_size,
            family: this.measure_number_font,
            anchor: "middle"
          }).rotate(270);
          this.windowGroup.add(fe_below_measure);
        }

        /********************************************************************************************************************************************************* frame extensions below ********* ending *********/

        /********************************************************************************************************************************************************* windowsill connection ********* beginning *********/

        if (this.windowsill_connection > 0) {

          let windowsillHeight = this.windowsill_connection / this.multiplier();

          let windowsillConnection = this.canvas
            .rect(this.totals.width + frame_ext_left_height + frame_ext_right_height, windowsillHeight)
            .stroke(this.svgStroke)
            .fill(this.windowsill_connection_color)
            .move(this.moveX - frame_ext_left_height, this.moveY + this.totals.height + measure_y_bottom);

          this.windowGroup.add(windowsillConnection);

          let space_to_right = this.moveX + this.totals.width + (this.spaceLeft - this.measure_line_space);

          let windowsillConnection_measure_h_line_top = this.canvas
            .line(space_to_right + frame_ext_right_height, this.moveY + this.totals.height + measure_y_bottom, space_to_right + (this.measure_line_space / 2) + frame_ext_right_height, this.moveY + this.totals.height + measure_y_bottom)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(windowsillConnection_measure_h_line_top);

          let windowsillConnection_measure_h_line_bottom = this.canvas
            .line(space_to_right + frame_ext_right_height, this.moveY + this.totals.height + windowsillHeight + measure_y_bottom, space_to_right + (this.measure_line_space / 2) + frame_ext_right_height, this.moveY + this.totals.height + windowsillHeight + measure_y_bottom)
            .stroke({
              color: this.measure_line_color,
              width: this.measure_line_thickness
            });
          this.windowGroup.add(windowsillConnection_measure_h_line_bottom);

          let windowsillConnection_measure = this.canvas.text(this.windowsill_connection + " ").move(space_to_right + this.measure_line_space + frame_ext_right_height, this.moveY + this.totals.height + measure_y_bottom + (windowsillHeight / 4)).font({
            fill: this.measure_line_color,
            size: this.measure_number_size,
            family: this.measure_number_font,
            anchor: "middle"
          });
          this.windowGroup.add(windowsillConnection_measure);

        }

        /********************************************************************************************************************************************************* windowsill connection ********* ending *********/


        /***************************************************************************** connecting lines **********************************/

        if (this.showConnectingLines) {

          let connecting_line_xy = this.blendRahmenBreiteBeiFestverglasung / this.multiplier();

          /*************************** Main Frame ******************************/
          let main_frame_connecting_line_left_top = this.canvas
            .line(this.moveX, this.moveY, this.moveX + connecting_line_xy, this.moveY + connecting_line_xy)
            .stroke({
              color: this.connecting_line_color,
              width: this.connecting_line_thickness
            });

          this.windowGroup.add(main_frame_connecting_line_left_top);

          let main_frame_connecting_line_right_top = this.canvas
            .line(this.moveX + this.totals.width, this.moveY, this.moveX + this.totals.width - connecting_line_xy, this.moveY + connecting_line_xy)
            .stroke({
              color: this.connecting_line_color,
              width: this.connecting_line_thickness
            });

          this.windowGroup.add(main_frame_connecting_line_right_top);

          let main_frame_connecting_line_left_bottom = this.canvas
            .line(this.moveX, this.moveY + this.totals.height, this.moveX + connecting_line_xy, this.moveY + this.totals.height - connecting_line_xy)
            .stroke({
              color: this.connecting_line_color,
              width: this.connecting_line_thickness
            });

          this.windowGroup.add(main_frame_connecting_line_left_bottom);

          let main_frame_connecting_line_right_bottom = this.canvas
            .line(this.moveX + this.totals.width, this.moveY + this.totals.height, this.moveX + this.totals.width - connecting_line_xy, this.moveY + this.totals.height - connecting_line_xy)
            .stroke({
              color: this.connecting_line_color,
              width: this.connecting_line_thickness
            });

          this.windowGroup.add(main_frame_connecting_line_right_bottom);

        }


        // iterate through the rows and columns of each row
        // to draw the glass, blendrahmen, and levers

        this.splitting.forEach((row, rowIndex) => {
            // we have to add the Rahmen to y for the first row
            // consecutive rows will get half the pfosten width added to it
            let rowX = measure_x_left;
            if (rowIndex === 0) {
              rowX = this.spaceLeft + measure_x_left;
            }

            let rowY = this.moveY;
            //let row_id = rowIndex;

            // this iterates through each column (section)
            row.section_widths.forEach((column, columnIndex) => {

              let column_id = columnIndex;

              let frameWidth = this.blendRahmenBreite;

              if (this.isF(row.section_codes[columnIndex])) {
                frameWidth = this.blendRahmenBreiteBeiFestverglasung;
              }


              this.segmentCounter++;

              /************************************************************************************** Width & X ***************************************************/
              /************************************************************************************** Width & X ***************************************************/

              let segment_row_X = 0;
              let last_column = row.section_widths.length;

              let width = column;

              if (row.section_widths.length > 1) {
                if (column_id > 0) {
                  // follow segments
                  if (last_column !== (column_id + 1)) {
                    // this is F
                    if (this.isF(row.section_codes[column_id])) {
                      width -= this.pfostenBreiteOhneFluegel;
                      segment_row_X += this.pfostenBreiteOhneFluegel / 2;
                      // this is D
                    } else {
                      width -= this.pfostenBreiteMitFluegel;
                      segment_row_X += this.pfostenBreiteMitFluegel / 2;
                    }
                  } else {
                    // the last segment
                    width -= frameWidth;

                    // this is F
                    if (this.isF(row.section_codes[column_id])) {
                      width -= this.pfostenBreiteOhneFluegel / 2;
                      segment_row_X += this.pfostenBreiteOhneFluegel / 2;
                      // this is D
                    } else {
                      width -= this.pfostenBreiteMitFluegel / 2;
                      segment_row_X += this.pfostenBreiteMitFluegel / 2;
                    }

                  }
                } else {
                  // the first segment
                  width -= frameWidth;
                  segment_row_X = frameWidth;

                  if (this.isF(row.section_codes[column_id])) {
                    width -= this.pfostenBreiteOhneFluegel / 2;
                    // is D
                  } else {
                    width -= this.pfostenBreiteMitFluegel / 2;
                  }

                  //pfostenBreite = this.pfostenBreiteOhneFluegel;

                }
              } else {
                segment_row_X += frameWidth;
                width -= frameWidth * 2;
              }

              //this.moveX += segment_row_X / this.multiplier();
              this.moveX = rowX + (segment_row_X / this.multiplier());


              width /= this.multiplier();

              /************************************************************************************** Height & Y ***************************************************/
              /************************************************************************************** Height & Y ***************************************************/

                // if (columnIndex === 0) {
                //   this.moveY += frameWidth / this.multiplier();
                // } else {
                //   //this.moveY += (this.splitting[columnIndex - 1].h_posts / 2) / this.multiplier();
                //   this.moveY = rowY + (frameWidth / this.multiplier());
                // }

              let height = row.height;
              let segment_row_y = 0;
              let count_row = this.splitting.length;

              //console.log(count_row)

              if (count_row > 1) {

                if (rowIndex === 1) {
                  // space correction for measures
                  this.moveX += this.spaceLeft;
                  this.moveY += this.spaceTop + add_more_height_for_frame_ext_above;
                  rowY += this.spaceTop + add_more_height_for_frame_ext_above;
                }

                if (rowIndex === 0) {
                  // first row
                  height -= frameWidth;

                  if (this.isF(row.section_codes[column_id])) {
                    height -= this.pfostenBreiteOhneFluegel / 2;
                    segment_row_y = this.pfostenBreiteOhneFluegel;
                  } else {
                    height -= this.pfostenBreiteMitFluegel / 2;
                    segment_row_y = this.pfostenBreiteMitFluegel;
                  }

                } else if ((rowIndex + 1) < count_row) {
                  // follow rows
                  height -= frameWidth;

                  if (this.isF(row.section_codes[column_id])) {
                    height -= this.pfostenBreiteOhneFluegel / 2;
                    segment_row_y = this.pfostenBreiteOhneFluegel / 2;
                  } else {
                    height -= this.pfostenBreiteMitFluegel / 2;
                    segment_row_y = this.pfostenBreiteMitFluegel / 2;
                  }

                } else {
                  // last row
                  height -= frameWidth;

                  if (this.isF(row.section_codes[column_id])) {
                    height -= this.pfostenBreiteOhneFluegel / 2;
                    segment_row_y = this.pfostenBreiteOhneFluegel / 2;
                  } else {
                    height -= this.pfostenBreiteMitFluegel / 2;
                    segment_row_y = this.pfostenBreiteMitFluegel / 2;
                  }

                }
              } else {
                // only one row
                height -= frameWidth * 2;
                segment_row_y = frameWidth;
              }

              this.moveY = rowY + (segment_row_y / this.multiplier());

              height /= this.multiplier();

              let fill = this.rahmenTextur;

              if (this.hatRahmenVerglasung(row.section_codes[columnIndex])) {

                fill = this.$vuetify.theme.themes[this.theme].windowGlass;

                if (this.$store.state.configuration.customerConfig.ornamental_glazing?.label && this.$store.state.configuration.customerConfig.ornamental_glazing?.label !== "Klarglas") {
                  fill = this.ornament_glazing_color;
                }


                /****************************************************************** Glasleisten Fest ***********************************************/

                let rect_glasleiste = this.canvas
                  .rect(width, height)
                  .stroke(this.svgStrokeGlasLeiste)
                  .fill("#ffffff")
                  .move(this.moveX, this.moveY);

                this.windowGroup.add(rect_glasleiste);

                width = width - ((this.glasLeiste * 2) / this.multiplier());
                height = height - ((this.glasLeiste * 2) / this.multiplier());
                this.moveX = this.moveX + (this.glasLeiste / this.multiplier());
                this.moveY = this.moveY + (this.glasLeiste / this.multiplier());

                let rect = this.canvas
                  .rect(width, height)
                  .stroke(this.svgStroke)
                  .fill(fill)
                  .move(this.moveX, this.moveY);
                this.windowGroup.add(rect);

              } else {

                let rect = this.canvas
                  .rect(width, height)
                  .stroke(this.svgStroke)
                  .fill(fill)
                  .move(this.moveX, this.moveY);
                this.windowGroup.add(rect);
              }

              /****************************************************************** connecting lines ***********************************************/

              if (this.showConnectingLines && !this.isF(row.section_codes[column_id])) {

                let connecting_line_xy = (this.fluegelRahmenBreite + this.glasLeiste) / this.multiplier();

                /*************************** Main Frame ******************************/
                let main_frame_connecting_line_left_top = this.canvas
                  .line(this.moveX, this.moveY, this.moveX + connecting_line_xy, this.moveY + connecting_line_xy)
                  .stroke({
                    color: this.connecting_line_color,
                    width: this.connecting_line_thickness
                  });

                this.windowGroup.add(main_frame_connecting_line_left_top);

                let main_frame_connecting_line_right_top = this.canvas
                  .line(this.moveX + width, this.moveY, this.moveX + width - connecting_line_xy, this.moveY + connecting_line_xy)
                  .stroke({
                    color: this.connecting_line_color,
                    width: this.connecting_line_thickness
                  });

                this.windowGroup.add(main_frame_connecting_line_right_top);

                let main_frame_connecting_line_left_bottom = this.canvas
                  .line(this.moveX, this.moveY + height, this.moveX + connecting_line_xy, this.moveY + height - connecting_line_xy)
                  .stroke({
                    color: this.connecting_line_color,
                    width: this.connecting_line_thickness
                  });

                this.windowGroup.add(main_frame_connecting_line_left_bottom);

                let main_frame_connecting_line_right_bottom = this.canvas
                  .line(this.moveX + width, this.moveY + height, this.moveX + width - connecting_line_xy, this.moveY + height - connecting_line_xy)
                  .stroke({
                    color: this.connecting_line_color,
                    width: this.connecting_line_thickness
                  });

                this.windowGroup.add(main_frame_connecting_line_right_bottom);
              }


              // the inner (glass) starting point will be the rahmen plus the fluegelRahmenBreite
              this.moveYInner = this.moveY + this.fluegelRahmenBreite / this.multiplier();

              // we only want to draw the inner rectangle if we do not have a
              // Rahmenverglasung
              this.moveXInner = this.moveX + this.fluegelRahmenBreite / this.multiplier();
              let widthInner = width - (2 * this.fluegelRahmenBreite) / this.multiplier();
              let heightInner = height - (2 * this.fluegelRahmenBreite) / this.multiplier();

              // for Glazing Bars
              this.moveXInner_GlazingBar = this.moveX + this.fluegelRahmenBreite / this.multiplier();
              this.moveYInner_GlazingBar = this.moveY + this.fluegelRahmenBreite / this.multiplier();
              let widthInner_GlazingBar = width - (2 * this.fluegelRahmenBreite) / this.multiplier();
              let heightInner_GlazingBar = height - (2 * this.fluegelRahmenBreite) / this.multiplier();

              if (!this.hatRahmenVerglasung(row.section_codes[columnIndex]) && widthInner > 0) {
                // the inner width / height is just the width of the rahmen minus two
                // times the width of the blendrahmen (left and right / up and down)

                /****************************************************************** Glasleisten Flügel ***********************************************/

                let rect_glasleiste = this.canvas
                  .rect(widthInner, heightInner)
                  .stroke(this.svgStrokeGlasLeiste)
                  .fill(fill)
                  .move(this.moveXInner, this.moveYInner);

                widthInner = widthInner - ((this.glasLeiste * 2) / this.multiplier());
                heightInner = heightInner - ((this.glasLeiste * 2) / this.multiplier());
                this.moveXInner = this.moveXInner + (this.glasLeiste / this.multiplier());
                this.moveYInner = this.moveYInner + (this.glasLeiste / this.multiplier());

                this.windowGroup.add(rect_glasleiste);

                let glazing_color = this.$vuetify.theme.themes[this.theme].windowGlass;
                if (this.$store.state.configuration.customerConfig.ornamental_glazing?.label && this.$store.state.configuration.customerConfig.ornamental_glazing?.label !== "Klarglas") {
                  glazing_color = this.ornament_glazing_color;
                }

                const rectInner = this.canvas
                  .rect(widthInner, heightInner)
                  .stroke(this.svgStroke)
                  .fill(glazing_color)
                  .move(this.moveXInner, this.moveYInner);

                this.windowGroup.add(rectInner);

              } else {
                // for Glazing Bars
                this.moveXInner_GlazingBar = this.moveX;
                this.moveYInner_GlazingBar = this.moveY;
                widthInner_GlazingBar = width;
                // eslint-disable-next-line no-unused-vars
                heightInner_GlazingBar = height;
              }


              /******************************************************************************************************************************* Segment Number ********************************/

              if (this.showSegmentNumbers) {

                let segment_number_width = this.segment_number_width;
                if (this.totalWidth < 500) {
                  segment_number_width = this.segment_number_width / 2;
                }

                let segment_number = this.canvas.text(this.segmentCounter + " ").move(this.moveX + (width / 2), this.moveY + (height / 2) - ((segment_number_width / this.multiplier()) / 1.2)).font({
                  fill: this.segment_color,
                  size: segment_number_width / this.multiplier(),
                  family: this.segment_number_font,
                  anchor: "middle"
                });

                this.windowGroup.add(segment_number);

              }


              /******************************************************************************************************************************* Infos ********************************/

              if (this.showInfosInSegment) {

                let ornament_label = "Klarglas";
                let opening_code_label = row.section_codes[column_id];
                if (this.$store.state.configuration.customerConfig.ornamental_glazing?.label) {
                  ornament_label = this.$store.state.configuration.customerConfig.ornamental_glazing.label;
                }

                let frame_for_info_x = (this.fluegelRahmenBreite + this.glasLeiste) / this.multiplier() + 3;
                let frame_for_info_y = (this.fluegelRahmenBreite + this.glasLeiste) / this.multiplier() + 15;
                let more_space_y = 0;
                let info_size = this.info_size;

                //console.log(this.totals.width)

                if (this.totals.width < 250) {
                  more_space_y = 4;
                  info_size -= 3;
                  frame_for_info_y = (this.fluegelRahmenBreite + this.glasLeiste) / this.multiplier() + 10;
                }

                if (this.isF(row.section_codes[column_id])) {
                  frame_for_info_x = this.glasLeiste / this.multiplier() + 3;
                  frame_for_info_y = this.glasLeiste / this.multiplier() + more_space_y;
                }

                let ornament_titel = this.canvas.text(ornament_label + " ").move(rowX + (segment_row_X / this.multiplier()) + frame_for_info_x, rowY + (segment_row_y / this.multiplier()) + height - frame_for_info_y).font({
                  fill: this.info_color,
                  size: info_size,
                  family: this.info_font,
                  anchor: "start"
                });

                this.windowGroup.add(ornament_titel);

                let opening_code_titel = this.canvas.text(opening_code_label + " ").move(rowX + (segment_row_X / this.multiplier()) + frame_for_info_x, rowY + (segment_row_y / this.multiplier()) + height - frame_for_info_y - info_size - 1).font({
                  fill: this.info_color,
                  size: info_size,
                  family: this.info_font,
                  anchor: "start"
                });

                this.windowGroup.add(opening_code_titel);

              }


              rowX += row.section_widths[columnIndex] / this.multiplier();


              // lets talk about handles
              // the inner (glass) starting point will be the rahmen plus the blendRahmenBreite

              // do we have a handle?
              if (this.hasHandle(row.section_codes[columnIndex]) && !this.isStulp(row.section_codes[columnIndex]) && !this.isHSTF(row.section_codes[columnIndex])) {

                // is it at the default position?
                if (row.handle_position_height === undefined) {
                  this.moveYHandle = this.moveYInner + (heightInner - this.handleHeight / this.multiplier()) / 2;
                } else {
                  // if we have an explicit height, we obviously want to use it
                  this.moveYHandle = this.moveYInner + heightInner - row.handle_position_height / this.multiplier();
                }

                this.moveYHandleBar = this.moveYHandle + this.handleHeight / 3 / this.multiplier();

                // where do we have the handle positioned
                switch (this.handlePosition(row.section_codes[columnIndex])) {
                  case "left":
                    this.moveXHandle = this.moveX + (this.fluegelRahmenBreite - this.handleWidth) / 2 / this.multiplier();
                    this.moveXHandleBar = this.moveX + ((this.fluegelRahmenBreite / 2) - (this.handleBarWidth / 2)) / this.multiplier();
                    break;
                  case "right":
                    this.moveXHandle = this.moveX + width - (this.handleWidth + this.fluegelRahmenBreite) / 2 / this.multiplier();
                    this.moveXHandleBar = this.moveX + width - ((this.fluegelRahmenBreite / 2) + (this.handleBarWidth / 2)) / this.multiplier();
                    break;
                }

                const rectHandle = this.canvas
                  .rect(
                    this.handleWidth / this.multiplier(),
                    this.handleHeight / this.multiplier()
                  )
                  .stroke(this.svgStroke)
                  .fill(this.handleColor)
                  .move(this.moveXHandle, this.moveYHandle);
                this.windowGroup.add(rectHandle);

                let pskHandleBarHeight = 0;
                let pskHandleBarY = 0;

                if (this.isPSK(row.section_codes[columnIndex])) {
                  pskHandleBarHeight = 65;
                  pskHandleBarY = 16;
                }

                const rectHandleBar = this.canvas
                  .rect(
                    this.handleBarWidth / this.multiplier(),
                    (this.handleBarHeight + pskHandleBarHeight) / this.multiplier()
                  )
                  .stroke(this.svgStroke)
                  .fill(this.handleColor)
                  .move(this.moveXHandleBar, this.moveYHandleBar - pskHandleBarY);
                this.windowGroup.add(rectHandleBar);
              }

              // here comes the lines

              if (this.hasHandle(row.section_codes[columnIndex])) {
                // if we have a handle, it can either be
                // tilted, turned or both

                // can it be tilted? if yes, draw the "tilt lines"
                if (this.canTilt(row.section_codes[columnIndex])) {
                  const line_1 = this.canvas
                    .line(
                      this.moveXInner,
                      this.moveYInner + heightInner,
                      this.moveXInner + widthInner / 2,
                      this.moveYInner
                    )
                    .stroke({
                      color: this.openingLinesColor,
                      width: this.openingLinesWidth,
                      dasharray: [2, 2],
                      dashoffset: 0
                    });
                  const line_2 = this.canvas
                    .line(
                      this.moveXInner + widthInner / 2,
                      this.moveYInner,
                      this.moveXInner + widthInner,
                      this.moveYInner + heightInner
                    )
                    .stroke({
                      color: this.openingLinesColor,
                      width: this.openingLinesWidth,
                      dasharray: [2, 2],
                      dashoffset: 0
                    });
                  this.windowGroup.add(line_1);
                  this.windowGroup.add(line_2);

                  // PSK

                  if (this.isPSK(row.section_codes[columnIndex])) {
                    console.log("psk?");
                    const line_psk = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) - 15,
                        this.moveYInner + heightInner - 20,
                        this.moveXInner + (widthInner / 2) + 15,
                        this.moveYInner + heightInner - 20
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(line_psk);

                    if (this.handlePosition(row.section_codes[columnIndex]) === "right") {
                      const arrow_up = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) - 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) - 5,
                          this.moveYInner + heightInner - 25
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(arrow_up);
                      const arrow_down = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) - 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) - 5,
                          this.moveYInner + heightInner - 15
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(arrow_down);
                      const psk_y_line = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) + 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) + 15,
                          this.moveYInner + heightInner - 30
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(psk_y_line);
                    } else {
                      const arrow_up = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) + 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) + 5,
                          this.moveYInner + heightInner - 25
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(arrow_up);
                      const arrow_down = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) + 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) + 5,
                          this.moveYInner + heightInner - 15
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(arrow_down);
                      const psk_y_line = this.canvas
                        .line(
                          this.moveXInner + (widthInner / 2) - 15,
                          this.moveYInner + heightInner - 20,
                          this.moveXInner + (widthInner / 2) - 15,
                          this.moveYInner + heightInner - 30
                        )
                        .stroke({
                          color: this.openingLinesColor,
                          width: this.openingLinesWidth,
                          dasharray: [2, 2],
                          dashoffset: 0
                        });
                      this.windowGroup.add(psk_y_line);
                    }

                  }
                }

                // can it be turned open? if yes, draw the "turn lines"
                if (this.canTurn(row.section_codes[columnIndex])) {
                  // which way does it open? left or right?
                  if (
                    this.handlePosition(row.section_codes[columnIndex]) ===
                    "right"
                  ) {
                    const line_1 = this.canvas
                      .line(
                        this.moveXInner,
                        this.moveYInner,
                        this.moveXInner + widthInner,
                        this.moveYInner + heightInner / 2
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    const line_2 = this.canvas
                      .line(
                        this.moveXInner + widthInner,
                        this.moveYInner + heightInner / 2,
                        this.moveXInner,
                        this.moveYInner + heightInner
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(line_1);
                    this.windowGroup.add(line_2);

                  } else {
                    const line_1 = this.canvas
                      .line(
                        this.moveXInner,
                        this.moveYInner + heightInner / 2,
                        this.moveXInner + widthInner,
                        this.moveYInner + heightInner
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    const line_2 = this.canvas
                      .line(
                        this.moveXInner + widthInner,
                        this.moveYInner,
                        this.moveXInner,
                        this.moveYInner + heightInner / 2
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(line_1);
                    this.windowGroup.add(line_2);
                  }
                }

                // HST
                if (this.isHSTL(row.section_codes[columnIndex]) || this.isHSTR(row.section_codes[columnIndex])) {
                  const line_hst = this.canvas
                    .line(
                      this.moveXInner + (widthInner / 2) - 15,
                      this.moveYInner + heightInner - 30,
                      this.moveXInner + (widthInner / 2) + 15,
                      this.moveYInner + heightInner - 30
                    )
                    .stroke({
                      color: this.openingLinesColor,
                      width: this.openingLinesWidth,
                      dasharray: [2, 2],
                      dashoffset: 0
                    });
                  this.windowGroup.add(line_hst);

                  if (this.isHSTL(row.section_codes[columnIndex])) {
                    const arrow_up = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) - 15,
                        this.moveYInner + heightInner - 30,
                        this.moveXInner + (widthInner / 2) - 5,
                        this.moveYInner + heightInner - 35
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(arrow_up);
                    const arrow_down = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) - 15,
                        this.moveYInner + heightInner - 30,
                        this.moveXInner + (widthInner / 2) - 5,
                        this.moveYInner + heightInner - 25
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(arrow_down);
                    const psk_y_line = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) + 15,
                        this.moveYInner + heightInner - 20,
                        this.moveXInner + (widthInner / 2) + 15,
                        this.moveYInner + heightInner - 30
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(psk_y_line);
                  }

                  if (this.isHSTR(row.section_codes[columnIndex])) {
                    const arrow_up = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) + 15,
                        this.moveYInner + heightInner - 30,
                        this.moveXInner + (widthInner / 2) + 5,
                        this.moveYInner + heightInner - 35
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(arrow_up);
                    const arrow_down = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) + 15,
                        this.moveYInner + heightInner - 30,
                        this.moveXInner + (widthInner / 2) + 5,
                        this.moveYInner + heightInner - 25
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(arrow_down);
                    const psk_y_line = this.canvas
                      .line(
                        this.moveXInner + (widthInner / 2) - 15,
                        this.moveYInner + heightInner - 20,
                        this.moveXInner + (widthInner / 2) - 15,
                        this.moveYInner + heightInner - 30
                      )
                      .stroke({
                        color: this.openingLinesColor,
                        width: this.openingLinesWidth,
                        dasharray: [2, 2],
                        dashoffset: 0
                      });
                    this.windowGroup.add(psk_y_line);
                  }

                }

              } else {
                // or we only have a glass which can not be opened

                // make the line width somewhat adaptive to look better
                // on small and big windows (svg width and height)
                let lineHWidth, lineVWidth;
                switch (true) {
                  case widthInner < 20:
                    lineHWidth = widthInner / 2;
                    break;
                  case widthInner < 80:
                    lineHWidth = widthInner / 3;
                    break;
                  default:
                    lineHWidth = widthInner / 4;
                    break;
                }
                switch (true) {
                  case heightInner < 40:
                    lineVWidth = heightInner / 2;
                    break;
                  case heightInner < 80:
                    lineVWidth = heightInner / 3;
                    break;
                  default:
                    lineVWidth = heightInner / 5;
                    break;
                }

                const line_1 = this.canvas
                  .line(
                    this.moveXInner + (widthInner / 2 - lineHWidth / 2), // x1
                    this.moveYInner + heightInner / 2, // y1
                    this.moveXInner + (widthInner / 2 + lineHWidth / 2), // x2
                    this.moveYInner + heightInner / 2 // y2
                  )
                  .stroke({
                    color: this.openingLinesColor,
                    width: this.openingLinesWidth,
                    dasharray: [2, 2],
                    dashoffset: 0
                  });
                const line_2 = this.canvas
                  .line(
                    this.moveXInner + widthInner / 2,
                    this.moveYInner + (heightInner / 2 - lineVWidth / 2),
                    this.moveXInner + widthInner / 2,
                    this.moveYInner + (heightInner / 2 + lineVWidth / 2)
                  )
                  .stroke({
                    color: this.openingLinesColor,
                    width: this.openingLinesWidth,
                    dasharray: [2, 2],
                    dashoffset: 0
                  });
                this.windowGroup.add(line_1);
                this.windowGroup.add(line_2);
              }

              // do we need to show the section numbers?
              if (this.$store.state.configuration.customerConfig.glazing_bars !== undefined) {

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// glazing bars
                if (this.$store.state.configuration.customerConfig.glazing_bars_divisions[String(this.segmentCounter) - 1] !== undefined &&
                  this.$store.state.configuration.customerConfig.glazing_bars_divisions[String(this.segmentCounter) - 1] !== null
                ) {
                  let glazingBarWidth = this.$store.state.configuration.customerConfig.glazing_bars.glazing_bar_width;

                  let glazingBarsDivisionCode = this.$store.state.configuration.customerConfig.glazing_bars_divisions[String(this.segmentCounter) - 1].glazing_bar_code;

                  const glazingBarsArray_V = glazingBarsDivisionCode.split("_");

                  let quantity_H = 0;
                  let quantity_V = 0;
                  let q_bars_H = [];
                  let q_bars_V = [];
                  for (const elements_V of glazingBarsArray_V) {
                    const glazingBarsArray_H = elements_V.split("-");
                    for (const elements_H of glazingBarsArray_H) {
                      switch (elements_H) {
                        case "nh":
                          q_bars_H[quantity_H] = elements_H;
                          quantity_H++;
                          break;
                        case "nv":
                          q_bars_V[quantity_V] = elements_H;
                          quantity_V++;
                          break;
                      }
                    }
                  }

                  /////////////////// horizontal

                  let divisor_H = 0;
                  switch (quantity_H) {
                    case 1:
                      divisor_H = 2;
                      break;
                    case 2:
                      // eslint-disable-next-line no-unused-vars
                      divisor_H = 3;
                      break;
                  }

                  let fromY = heightInner_GlazingBar / divisor_H;
                  let start_y = 0;
                  let h = 0;

                  for (const elements_H of q_bars_H) {
                    switch (elements_H) {
                      case "nh":
                        if (h === 0) {
                          start_y = this.moveYInner_GlazingBar;
                        }
                        // eslint-disable-next-line no-case-declarations
                        const glazingBar_H = this.canvas
                          .rect(widthInner_GlazingBar, glazingBarWidth / this.multiplier())
                          //.stroke(this.svgStroke)
                          .fill(this.glazingBarColor)
                          .move(
                            this.moveXInner_GlazingBar,
                            start_y +
                            fromY -
                            glazingBarWidth / 2 / this.multiplier()
                          );
                        this.windowGroup.add(glazingBar_H);
                        fromY += fromY;
                        h++;
                        break;
                    }
                  }

                  /////////////////// vertical

                  let divisor_V = 0;
                  switch (quantity_V) {
                    case 1:
                      divisor_V = 2;
                      break;
                    case 2:
                      // eslint-disable-next-line no-unused-vars
                      divisor_V = 3;
                      break;
                  }

                  let fromX = widthInner_GlazingBar / divisor_V;
                  let start_x = 0;
                  let v = 0;

                  for (const elements_V of q_bars_V) {
                    switch (elements_V) {
                      case "nv":
                        if (v === 0) {
                          start_x = this.moveXInner_GlazingBar;
                        }
                        // eslint-disable-next-line no-case-declarations
                        const glazingBar_V = this.canvas
                          .rect(
                            glazingBarWidth / this.multiplier(),
                            heightInner_GlazingBar
                          )
                          //.stroke(this.svgStroke)
                          .fill(this.glazingBarColor)
                          .move(
                            start_x +
                            fromX -
                            glazingBarWidth / 2 / this.multiplier(),
                            this.moveYInner_GlazingBar
                          );
                        this.windowGroup.add(glazingBar_V);
                        fromX += fromX;
                        v++;
                        break;
                    }
                  }
                }

                //console.log(this.glazingBarsDivision(String(this.segmentCounter) - 1));
              }

              // move X to the next section's starting point
              this.moveX += width;
            });

            // reset x to 0 for next row
            this.moveX = 0;

            // set y to the next row's starting point by adding the current height

            // create an array of all row heights
            const heights = this.splitting.map((s) => s.height);

            // get rid of all heights of "future" rows, as in all
            // rows that will be drawn later
            heights.splice(rowIndex + 1);

            // if the heights array has a length of 0 => we do not have any more
            // row to draw, so we set the height to zero
            const moveY =
              heights.length > 0
                ? heights.reduce((previousValue, currentValue) => {
                  return previousValue + currentValue;
                })
                : 0;

            this.moveY = moveY / this.multiplier();
          }
        )
        ;
      } catch {
        return;
      }
    },
    drawSvg() {
      const valid =
        this.totalHeight >= Number(this.openingMinHeight) &&
        this.totalHeight <= Number(this.openingMaxHeight) &&
        this.totalWidth >= Number(this.openingMinWidth) &&
        this.totalWidth <= Number(this.openingMaxWidth);

      if (valid) {
        this.cleanCanvas(this.id);
        this.drawWindow();
        //console.log('Grafik neu erzeugt!')
      }
    },
    multiplier() {
      const widthMultiplier = this.totalWidth / this.svgMaxWidth;
      const heightMultiplier = this.totalHeight / this.svgMaxHeight;
      if (this.totalWidth / heightMultiplier > this.svgMaxWidth) {
        return widthMultiplier;
      } else {
        return heightMultiplier;
      }
    }
  }
  ,
  mounted() {
    this.$root.$on("sectionChanged", () => {
      this.drawSvg();
    });
  }
}
;
</script>

<style scoped></style>
