<template>
  <div v-if="materials.enabled">
    <div v-if="apiLoading.materials">
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-row class="mt-1">
        <v-col
            cols="12"
            :md="columnWidth(columns)"
            v-for="i in columns"
            :key="i"
        >
          <v-skeleton-loader
              type="card, list-item-two-line"
              class="py-1 px-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <span class="model"></span>
    </div>

    <item-selection
        v-else
        item-type="materials"
        :max-items="8"
        :columns="4"
        :item="materials"
        id="materials-container"
    />
  </div>
</template>
<script>
import {mainMixin} from "@/mixins/mainMixin";
import ItemSelection from "@/components/ItemSelection";

export default {
  name: "Material",
  mixins: [mainMixin],
  components: {ItemSelection},
  data() {
    return {
      columns: 3,
    };
  },
  computed: {
    materials() {
      return this.$store.state.productData.materials;
    }
  }
};
</script>
