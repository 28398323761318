<template>
  <div v-if="selectableItems.total > 0" class="mb-8">
    <section-header :item-type="itemType" />
    <div class="px-3 py-3">
      <v-row>
        <v-col>
          <div v-html="$store.getters['productData/descriptionShortMenu'](itemType)" style="font-size: 14px"></div>
        </v-col>
      </v-row>
    </div>
    <v-btn-toggle
      v-if="isOptional"
      v-model="showOptional"
      mandatory
      style="width: 100%"
      color="primary"
      class="d-flex mt-0 mb-10"
    >
      <v-btn
        :value="false"
        @click="toggleOptionalConfig(false)"
        :class="{'active-btn': !optionalConfigActive, 'inactive-btn': optionalConfigActive}"
        class="flex-grow-1"
      >
        {{ $t("optional.skip") }}
        <v-icon v-if="!optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
      <v-btn
        :value="true"
        @click="toggleOptionalConfig(true)"
        :class="{'active-btn': optionalConfigActive, 'inactive-btn': !optionalConfigActive}"
        class="flex-grow-1"
        :id="'optional_button_active_' + itemType"
      >
        {{ $t("optional.enable") }}
        <v-icon v-if="optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div v-if="!isOptional || (isOptional && showOptional)">

      <div>
        <!-- Items with tabs if group_name is set -->

        <v-tabs v-model="activeTab" v-if="isGroupItems === true">
          <!-- Erzeuge Tab-Titel -->
          <v-tab v-for="(items, key, index) in groupedItems" :key="index">
            {{ key }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab" v-if="isGroupItems === true">
          <!-- Erzeuge Inhalte für jeden Tab -->
          <v-tab-item v-for="(items, key, index) in groupedItems" :key="index">
            <v-card>
              <v-card-text class="tab-text">
                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    :lg="columnWidth(columns)"
                    v-for="item in items"
                    :key="item.id"
                  >
                    <v-slide-y-transition>
                      <selectionCard :item="item" :item-type="itemType" @load="activateTab(index)" />
                    </v-slide-y-transition>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <!-- normal list of Items -->

        <div
          v-for="(items, key, index) in groupedItems"
          :key="index"
          class="item-row"
        >
          <div v-if="key !== 'undefined' && isGroupItems === false">
            <h3 class="text-caption">{{ key }}</h3>
            <v-divider class="mb-3 mt-1"></v-divider>
          </div>

          <v-row v-if="isGroupItems === false">
            <v-col
              cols="6"
              sm="3"
              :lg="columnWidth(columns)"
              v-for="item in items"
              :key="item.id"
            >
              <v-slide-y-transition>
                <selectionCard :item="item" :img_max_height="img_max_height" :item-type="itemType" />
              </v-slide-y-transition>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="12" class="d-flex justify-end" style="padding-top: 0">
            <v-switch
              v-if="selectableItems.total > maxItems && isGroupItems === false"
              v-model="showAll"
              :label="$t('defaultTexts.showAll')"
              class="mt-0"
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mainMixin } from "@/mixins/mainMixin";
import SectionHeader from "@/components/SectionHeader";
import SelectionCard from "@/components/SelectionCard";
import Vue from "vue";
//import {mapActions} from "vuex";

export default {
  name: "ItemSelection",
  components: { SelectionCard, SectionHeader },
  props: {
    maxItems: {
      type: [Number, String],
      default: 100
    },
    itemType: {
      type: String,
      required: true
    },
    item: {},
    columns: {
      type: Number,
      default: 3
    },
    img_max_height: {
      type: Number,
      default: 218
    }
  },
  mixins: [mainMixin],
  data() {
    return {
      showAll: false,
      showOptional: false,
      activeTab: 0,
      clickedDivisionId: null,
      optionalConfigActive: null
    };
  },
  computed: {
    getDivisions() {
      return this.$store.state.productData.model.divisions;
    },
    getProfiles() {
      return this.$store.state.productData.profiles;
    },
    // we want to be able to conditionally show only x items at first
    // but be able to have the customer - via switch - to show all items
    selectableItems() {
      let a_items = this.showAll
        ? this?.item?.items
        : this?.item?.items?.slice(0, this.maxItems);
      if (this.isGroupItems === true) {
        a_items = this?.item?.items;
      }
      return {
        total: this?.item?.items?.length,
        items: a_items
      };
    },
    // some items may be categorized by a group_name property.
    // so we oughta group the selectable items by this group name
    groupedItems() {
      return this.groupArrayBy(
        this?.selectableItems?.items,
        (item) => item?.group_name
      );
    },
    isGroupItems() {
      let isGroupItem = false;
      let a_items = this?.item?.items;
      if (a_items && a_items.length > 0) {
        for (let i = 0; i < a_items.length; i++) {
          if (a_items[i]["group_name"]) {
            isGroupItem = true;
          }
        }
      }
      return isGroupItem;
    },
    isOptional() {
      return this.$store.state.productData.model?.[this.itemType]?.isOptional;
    },
    activeTabFromType() {
      let tap = 0;

      switch (this.itemType) {
        case "openings":
          if (this.$store.state.setup.loadProductType && this.$store.state.setup.loadProductType !== "undefined") {
            tap = parseInt(this.$store.state.setup.loadProductType);
          }
          if (this.$store.state.setup.reloadProductOpeningTab && this.$store.state.setup.reloadProductOpeningTab !== "undefined") {
            tap = parseInt(this.$store.state.setup.reloadProductOpeningTab);
          }
          if (this.$store.state.productData.setupTemp?.reloadProductOpeningTab && this.$store.state.setup.reloadProductOpeningTab !== "undefined") {
            tap = parseInt(this.$store.state.productData.setupTemp?.reloadProductOpeningTab);
          }
      }
      return tap;
    }
  },
  created() {
    this.setActiveTab(this.activeTabFromType);
  },
  mounted() {
    this.checkDataIntervalForSetDivision = setInterval(this.setDivision, 500);
    this.checkDataIntervalForOptionIsActive = setInterval(this.activate_optional_button_if_item_exist, 1000);
  },
  methods: {
    setDivision() {
      if (this.itemType === "divisions") {
        if (this.itemType === "divisions" && this.hasValidUuid && this.getDivisions && this.getDivisions.items.length > 0) {
          const divisionId = this.$store.state.productData.customerConfigTemp?.divisions?.id;
          if (divisionId > 0) {
            // Nur klicken, wenn der Klick nicht bereits ausgeführt wurde
            if (!this.clickedDivisionId || this.clickedDivisionId !== divisionId) {
              this.clickAutomaticallyCategory("divisions", divisionId);
              this.clickedDivisionId = divisionId; // Speichere die geklickte Division-ID, um Doppelaufrufe zu verhindern
              setTimeout(() => {
                Vue.set(this.$store.state.configuration, "customerConfig", this.$store.state.productData.customerConfigTemp);
                Vue.set(this.$store.state, "setup", this.$store.state.productData.setupTemp);
                if (this.$store.getters["setup/getDeleteUuid"] === "1") {
                  Vue.set(this.$store.state.productData.customerConfigTemp, "uuid", "");
                }
                this.$root.$emit("sectionChanged");
              }, 500);
            }
          }
          clearInterval(this.checkDataIntervalForSetDivision);
        }
      } else {
        clearInterval(this.checkDataIntervalForSetDivision);
      }
    },
    setProfile() {
      if (this.itemType === "profiles" && this.getProfiles.items && this.getProfiles.items.length > 0) {
        const setItem = this.$store.state.configuration.customerConfig.profiles?.id || 0;
        const itemIsInStore = this.getProfiles.items.some(item => item.id === setItem);
        if (!itemIsInStore) {
          const firstProfileItemFromStore = this.getProfiles.items[0];
          this.$nextTick(() => {
            this.clickAutomaticallyCategory("profile", firstProfileItemFromStore.id);
          });
        }
      }
    },
    clickAutomaticallyCategory(category, itemId) {
      const elementToClick = document.getElementById(category + "_" + itemId);
      if (elementToClick) {
        elementToClick.click();
      }
    },
    activate_optional_button_if_item_exist() {
      console.log('ist da')
      if (this.hasValidUuid && this.isOptional) {
        const customerConfigItem = this.$store.state.configuration.customerConfig[this.itemType]?.id;
        if (customerConfigItem && customerConfigItem > 0) {
          this.clickOptionalButton(this.itemType);
          clearInterval(this.checkDataIntervalForOptionIsActive);
        }
      } else {
        clearInterval(this.checkDataIntervalForOptionIsActive);
      }
    },
    clickOptionalButton(category) {
      const buttonToClick = document.getElementById("optional_button_active_" + category);
      if (buttonToClick) {
        buttonToClick.click();
      }
    },
    activateTab(index) {
      Vue.set(this.$store.state.setup, "reloadProductOpeningTab", index);
    },
    setActiveTab(value) {
      this.activeTab = value;
    },
    async toggleOptionalConfig(value) {
      this.optionalConfigActive = value;
      const payload = {
        itemType: this.itemType,
        value: value
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload
      );
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );

    }
  },
  watch: {
    "getProfiles.items": {
      handler: function() {
        this.setProfile();
      },
      deep: true // Fügt sicher, dass alle Veränderungen in `items` erfasst werden
    },
    "item.items": {
      handler() {
        // this.showAll = false;
      }
    },
    showAll: {
      handler() {
        const payload = {
          itemType: this.itemType,
          maxItems: this.maxItems
        };
        this.$store.dispatch("productData/moveItemToStayVisible", payload);
      }
    }
  }
};
</script>
<style scoped>
.item-row {
  margin-bottom: 2rem;
}

.v-tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 12px !important;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.0892857143em;
  line-height: normal;
  min-width: 89px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tab-text {
  padding: 15px 0 0 0 !important;
}

/deep/ .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn.active-btn {
  border: 2px solid #F59100 !important;
  background-color: white !important;
  border-radius: 4px !important;
}

.inactive-btn {

}

.letwork-card-icon {
  position: absolute !important;
  right: -20px;
  top: -23px;
  border-radius: 2px;
  padding: 2px;
  background-color: #F59100;
  font-size: 25px;
  color: white !important;
  z-index: 2;
}
</style>
