<template>
  <v-expansion-panels :id="itemType" style="z-index: auto;">
    <v-expansion-panel>
      <v-expansion-panel-header
          class="header-panel"
          :disabled="!$store.getters['productData/descriptionLongMenu'](itemType)"
          :expand-icon="$store.getters['productData/descriptionLongMenu'](itemType) ? 'mdi-menu-down' : ''"
          :ref="$store.getters['productData/labelMenu'](itemType)"
      >
        <h2
            v-text="$store.getters['productData/labelMenu'](itemType)"
            class="font-weight-light header-title"
        ></h2>
        <template
            v-if="$store.getters['productData/descriptionLongMenu'](itemType)"
            v-slot:actions
        >
          <div>
            <span
                v-text="buttonText"
                class="mr-1 text-uppercase text-caption"
            ></span>
            <v-icon>$expand</v-icon>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content
          v-html="$store.getters['productData/descriptionLongMenu'](itemType)"
          class="px-6 pt-5 pb-1"
      >
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "section-header",
  props: {
    itemType: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonText() {
      return this.$t('header.buttonMoreInfos');
    },
  },
};
</script>

<style scoped>
.header-panel {
  border-bottom: 3px solid #1f324b; /* Hier die gewünschte Rahmenfarbe einstellen */
  background-color: transparent; /* Hintergrundfarbe entfernen */
  box-shadow: none;
  padding-left: 10px !important;
}
.header-title {
  color: #000; /* Hier die gewünschte Schriftfarbe einstellen */
}
</style>