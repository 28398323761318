<template>
  <div v-if="profiles.enabled">
    <div v-if="apiLoading.profiles">
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-row class="mt-1">
        <v-col
            cols="12"
            :md="columnWidth(columns)"
            v-for="i in columns"
            :key="i"
        >
          <v-skeleton-loader
              type="card, list-item-two-line"
              class="py-1 px-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <span class="model"></span>
    </div>

    <item-selection
        v-else
        item-type="profiles"
        :max-items="99"
        :columns="4"
        :item="profiles"
        id="profiles-container"
    />
  </div>
</template>
<script>
import {mainMixin} from "@/mixins/mainMixin";
import ItemSelection from "@/components/ItemSelection";

export default {
  name: "Profile",
  mixins: [mainMixin],
  components: {ItemSelection},
  data() {
    return {
      columns: 3,
      checkDataInterval: null
    };
  },
  computed: {
    profiles() {
      return this.$store.state.productData.profiles;
    },
    uuid() {
      return this.$store.getters["setup/getUUID"];
    },
    uuidCustomerConfig() {
      return this.$store.state.configuration.customerConfig.uuid;
    },
    profilesCustomerConfig() {
      return this.$store.state.configuration.customerConfig.profiles;
    }
  },
  // watch: {
  //   profiles: {
  //     handler(newProfiles) { // 'oldProfiles' entfernt, nur 'newProfiles' verwendet
  //       // Nur weitermachen, wenn neue Profile-Daten vorhanden sind
  //       if (newProfiles && newProfiles.items && newProfiles.items.length > 0) {
  //         this.onComponentLoaded();
  //       }
  //     },
  //     immediate: true, // Der Watcher wird auch bei der Initialisierung ausgeführt
  //   },
  // },
  mounted() {
    this.checkDataInterval = setInterval(this.checkProfilesLoaded, 500);
  },
  methods: {
    checkProfilesLoaded() {
      // reload from uuid
      if (this.uuid && this.uuid !== "0"){
        if (this.profiles && this.profiles.items && this.profiles.items.length > 0 && this.uuidCustomerConfig && this.uuidCustomerConfig !== "") {
          clearInterval(this.checkDataInterval); // Stoppt die wiederholte Überprüfung
          this.onComponentLoaded();
        }
      } else {
        if (this.profiles && this.profiles.items && this.profiles.items.length > 0) {
          clearInterval(this.checkDataInterval); // Stoppt die wiederholte Überprüfung
          this.onComponentLoaded();
        }
      }

    },
    onComponentLoaded() {
      let loadProfileId = this.$store.getters["setup/getLoadProductId"];

      if (this.uuid && this.uuid !== "0"){
        this.clickAutomaticallyProfiles(this.profilesCustomerConfig.id);
      } else if (loadProfileId === "0") {
        let firstProfileItem = this.profiles?.items[0];
        if (this.$store.state.configuration.customerConfig.profiles === null && firstProfileItem.id > 0) {
          this.clickAutomaticallyProfiles(firstProfileItem.id);
        }
      } else {
        if (this.$store.state.configuration.customerConfig.profiles === null) {
          this.clickAutomaticallyProfiles(loadProfileId);
        } else {
          let firstProfileItem = this.profiles?.items[0];
          if (firstProfileItem.id > 0) {
            this.clickAutomaticallyProfiles(firstProfileItem.id);
          }
        }
      }
    },
    clickAutomaticallyProfiles(profileId) {
      const checkProfileIdExist = setInterval(() => {
        const profileElement = document.getElementById("profile_" + profileId);
        if (profileElement) {
          profileElement.click();
          clearInterval(checkProfileIdExist);
        }
      }, 100);
    }
  }
};
</script>
