<template>
  <div v-if="model.enabled" id="model">
    <div v-if="apiLoading.model">
      <div v-for="counter in 7" :key="counter" class="mb-8">
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-row class="mt-1">
          <v-col
            cols="12"
            :md="columnWidth(columns)"
            v-for="i in columns"
            :key="i"
          >
            <v-skeleton-loader
              type="card, list-item-two-line"
              class="py-1 px-2"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <span class="model"></span>
      </div>
    </div>
    <div v-else>
      <item-selection
          item-type="profile_designs"
          :max-items="4"
          :columns="4"
          :item="model.profile_designs"
      />
      <item-selection
        item-type="divisions"
        :max-items="4"
        :item="model.divisions"
        :columns="3"
        :img_max_height="140"
      />
      <item-selection
        item-type="openings"
        :max-items="4"
        :item="model.openings"
        :columns="4"
      />
      <Dimensions />
      <item-selection
        item-type="decor_inside"
        :max-items="6"
        :item="model.decor_inside"
        :columns="6"
      />
      <item-selection
        item-type="decor_outside"
        :max-items="6"
        :item="model.decor_outside"
        :columns="6"
      />
      <item-selection
        item-type="glazing"
        :max-items="4"
        :item="model.glazing"
        :columns="4"
      />
      <item-selection
        item-type="spacers"
        :max-items="6"
        :item="model.spacers"
        :columns="6"
      />
      <item-selection
          item-type="ornamental_glazing"
          :max-items="6"
          :item="model.ornamental_glazing"
          :columns="6"
      />
      <GlazingBars
        item-type="glazing_bars"
        :max-items="4"
        :item="model.glazing_bars"
        :columns="4"
      />
      <item-selection
        item-type="sealing_color"
        :max-items="4"
        :item="model.sealing_color"
        :columns="4"
      />
      <item-selection
        item-type="security_packages"
        :max-items="4"
        :item="model.security_packages"
        :columns="4"
      />
      <item-selection
        item-type="windowsill_connections"
        :max-items="8"
        :item="model.windowsill_connections"
        :columns="6"
      />
      <item-selection
        item-type="equipment"
        :max-items="4"
        :item="model.equipment"
        :columns="4"
      />
      <FrameExtensions
        item-type="frame_extensions"
        :max-items="4"
        :item="model.frame_extensions"
        :columns="4"
      />
    </div>
  </div>
</template>
<script>
import ItemSelection from "@/components/ItemSelection";
import Dimensions from "@/components/Dimensions";
import GlazingBars from "@/components/GlazingBars";
import FrameExtensions from "@/components/FrameExtensions";
import { mainMixin } from "@/mixins/mainMixin";

export default {
  name: "Model",
  components: { GlazingBars, ItemSelection, Dimensions, FrameExtensions },
  mixins: [mainMixin],
  data() {
    return {
      columns: 3,
    };
  },
  computed: {
    model() {
      return this.$store.state.productData.model;
    },
  }
};
</script>
