<template>
  <div>
    <v-row>
      <v-col class="order-lg-first" id="config-container">
        <configuration/>
      </v-col>
      <v-col cols="12" md="8">
        <material class="mb-8" :columns="4"/>
        <series class="mb-8" :columns="4"/>
        <profile class="mb-8" :columns="4"/>
        <model class="mb-8" :columns="4"/>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
#config-container {
  max-width: 100%;
}

@media (max-width: 960px) {
  #config-container {
    min-width: 325px;
    order: 4;
  }
}

@media (min-width: 960px) {
  #config-container {
    min-width: 325px;
    order: 4;
  }
}

</style>
<script>
import Configuration from "@/components/Configuration";
import Material from "@/components/Material";
import Series from "@/components/Series";
import Profile from "@/components/Profile";
import Model from "@/components/Model";

export default {
  name: "Index",
  components: {Material, Series, Profile, Model, Configuration},
  created() {
    let uuid = this.$store.getters["setup/getUUID"];
    if (uuid && uuid !== "0") {
      this.$store.dispatch("configuration/setConfigurationFromUuid").then(() => {
        this.$store.dispatch("productData/getMaterials").then(() => {
          this.afterMaterialsLoaded();
        }).catch(error => {
          console.error("Fehler beim Laden der Materialien:", error);
        });
      });
    } else {
      this.$store.dispatch("productData/getMaterials").then(() => {
        this.afterMaterialsLoaded();
      }).catch(error => {
        console.error("Fehler beim Laden der Materialien:", error);
      });
    }
  },
  methods: {
    afterMaterialsLoaded() {
      let loadMaterialId = this.$store.getters["setup/getLoadMaterialId"];
      //let reloadProduct = this.$store.getters["setup/getReloadProduct"];
      this.$store.dispatch("setup/setOverlayLoader2", true);
      if (loadMaterialId === "0") {
        let firstMaterialItem = this.$store.state.productData.materials?.items[0];
        if (this.$store.state.configuration.customerConfig.materials === null && firstMaterialItem.id > 0) {
          this.clickAutomaticallyMaterial(firstMaterialItem.id);
        }
      } else {
        if (this.$store.state.configuration.customerConfig.materials === null) {
          this.clickAutomaticallyMaterial(loadMaterialId);
        }
      }
    },
    clickAutomaticallyMaterial(materialId) {
      const checkExist = setInterval(() => {
        const element = document.getElementById("material_" + materialId);
        if (element) {
          element.click();
          clearInterval(checkExist);
        }
      }, 100);
    }
  },
  data() {
    return {};
  },
};
</script>
