<template>
  <div v-if="series.enabled">
    <div v-if="apiLoading.series">
      <v-skeleton-loader type="article"></v-skeleton-loader>
      <v-row class="mt-1">
        <v-col
            cols="12"
            :md="columnWidth(columns)"
            v-for="i in columns"
            :key="i"
        >
          <v-skeleton-loader
              type="card, list-item-two-line"
              class="py-1 px-2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <span class="model"></span>
    </div>

    <item-selection
        v-else
        item-type="series"
        :max-items="8"
        :columns="4"
        :item="series"
        id="series-container"
    />
  </div>
</template>
<script>
import {mainMixin} from "@/mixins/mainMixin";
import ItemSelection from "@/components/ItemSelection";

export default {
  name: "Series",
  mixins: [mainMixin],
  components: { ItemSelection },
  data() {
    return {
      columns: 3,
      checkDataIntervalSeries: null
    };
  },
  computed: {
    series() {
      return this.$store.state.productData.series;
    },
    uuid() {
      return this.$store.getters["setup/getUUID"];
    },
    uuidCustomerConfig() {
      return this.$store.state.configuration.customerConfig.uuid;
    },
    seriesCustomerConfig() {
      return this.$store.state.configuration.customerConfig.series;
    }
  },
  mounted() {
    this.checkDataIntervalSeries = setInterval(this.checkSeriesLoaded, 200);
  },
  // watch: {
  //   series: {
  //     handler(newSeries) {
  //       // Nur weitermachen, wenn neue Series-Daten vorhanden sind
  //       if (newSeries && newSeries.items && newSeries.items.length > 0) {
  //         this.onComponentLoaded();
  //       }
  //     },
  //     immediate: true, // Der Watcher wird auch bei der Initialisierung ausgeführt
  //   },
  // },
  methods: {
    checkSeriesLoaded() {
      // reload from uuid
      if (this.uuid && this.uuid !== "0"){
        if (this.series && this.series.items && this.series.items.length > 0 && this.uuidCustomerConfig && this.uuidCustomerConfig !== "") {
          clearInterval(this.checkDataIntervalSeries); // Stoppt die wiederholte Überprüfung
          this.onComponentLoaded();
        }
      } else {
        if (this.series && this.series.items && this.series.items.length > 0) {
          clearInterval(this.checkDataIntervalSeries); // Stoppt die wiederholte Überprüfung
          this.onComponentLoaded();
        }
      }
    },
    onComponentLoaded() {
      let loadSeriesId = this.$store.getters["setup/getLoadSeriesId"];
      if (this.uuid && this.uuid !== "0"){
        this.clickAutomaticallySeries(this.seriesCustomerConfig.id);
      } else if (loadSeriesId === "0") {
        let firstSeriesItem = this.series?.items[0];
        if (this.$store.state.configuration.customerConfig.series === null && firstSeriesItem.id > 0) {
          this.clickAutomaticallySeries(firstSeriesItem.id);
        }
      } else {
        if (this.$store.state.configuration.customerConfig.series === null) {
          this.clickAutomaticallySeries(loadSeriesId);
        }
      }
    },
    clickAutomaticallySeries(seriesId) {
      const checkSeriesIdExist = setInterval(() => {
        const seriesElement = document.getElementById("series_" + seriesId);
        if (seriesElement) {
          seriesElement.click();
          clearInterval(checkSeriesIdExist);
        }
      }, 100);
    }
  }
};
</script>
