<template>
  <div v-if="dimensions.enabled" class="mb-8">
    <section-header item-type="dimensions" />
    <div class="px-3 py-3">
      <div v-html="$store.getters['productData/descriptionShortMenu']('dimensions')" style="font-size: 14px"></div>
    </div>
    <div>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field
            v-model.number="totalWidth"
            ref="total_width"
            type="number"
            step="1"
            :label="$t('dimension.titleWidth')"
            prepend-inner-icon="mdi-ruler"
            @keyup.native.enter="$event.target.blur()"
            @focus="selectAll"
            @focusout="
              totalWidthCheck({
                column: 0,
                row: 0,
                check_type: 'single_width',
              })
            "
            :rules="[minMaxWidthCheck]"
            :error-messages="dimensionErrors.totalWidth"
          >
            <template v-slot:append>
              <v-icon v-if="dimensionErrors.totalWidth" color="error">
                mdi-cancel
              </v-icon>
              <v-icon v-else color="success"> mdi-check</v-icon>
            </template>
          </v-text-field>
          <v-alert
            text
            class="text-center mt-1 text-body-1"
            color="info"
            v-text="$t('dimension.info.allowedWidth', {width: total_width,})
            "
          ></v-alert>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="totalHeight"
            type="number"
            step="1"
            :label="$t('dimension.titleHeight')"
            prepend-inner-icon="mdi-ruler"
            @keyup.native.enter="$event.target.blur()"
            @focus="selectAll"
            @focusout="
              totalHeightCheck({
                column: 0,
                row: 0,
                check_type: 'single_height',
              })
            "
            :rules="[minMaxHeightCheck]"
            :error-messages="dimensionErrors.totalHeight"
          >
            <template v-slot:append>
              <v-icon v-if="dimensionErrors.totalHeight" color="error">
                mdi-cancel
              </v-icon>
              <v-icon v-else color="success"> mdi-check</v-icon>
            </template>
          </v-text-field>
          <v-alert
            text
            class="text-center mt-1 text-body-1"
            color="info"
            v-text="$t('dimension.info.allowedHeight', {height: total_height,})"
          ></v-alert>
        </v-col>
      </v-row>
      <v-row justify="center" class="flex-row">
        <v-col cols="8" :id="setIdAutomaticReloading">
          <!--          <v-img :src="imageURL(image)" contain height="300"></v-img>-->
          <window
            class="mt-1 mb-1"
            :id="`window_${Math.round(Math.random() * 100000000)}`"
            :svg-max-height="svgMaxHeight"
            :svg-max-width="svgMaxWidth"
            :image-url="decorUrl()"
            :opening-code="customerConfig.openings ? customerConfig.openings.opening_code: ''"
            :opening-min-width="customerConfig.openings ? customerConfig.openings.min_width : 200"
            :opening-max-width="customerConfig.openings ? customerConfig.openings.max_width : 400"
            :opening-min-height="customerConfig.openings ? customerConfig.openings.min_height : 200"
            :opening-max-height="customerConfig.openings ? customerConfig.openings.max_height : 400"
            :splitting="splitting"
            :total-width="customerConfig.dimensions ? customerConfig.dimensions.total_width: 1000"
            :total-height="customerConfig.dimensions? customerConfig.dimensions.total_height: 1000"
            :use-textures="true"
            :use-input-fields="false"
            :show-connecting-lines="true"
            :show-infos-in-segment="true"
            :show-segment-numbers="true"
            handle-color="#FFFFFF"
          />

          <div v-if="showSplittingRows && ifNotHST" class="d-flex justify-center">
            <v-row
              v-for="(column, index1) in splitting"
              justify="center"
              :key="index1"
            >
              <v-col
                v-for="(section, index) in column.section_widths"
                :key="index"
              >
                <v-text-field
                  v-model.number="column.section_widths[index]"
                  class="mx-2"
                  style="max-width: 100px"
                  type="Number"
                  step="1"
                  :id="'segment_width_' + index1 + '_' + index"
                  @keyup.native.enter="$event.target.blur()"
                  @focus="selectAll"
                  @focusout="
                    checkSegment({
                      column: index1,
                      row: index,
                      check_type: 'horizontal',
                    })
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          v-if="showSplittingColumns"
          cols="auto"
          class="d-flex align-center"
        >
          <div class="flex-column">
            <v-text-field
              v-for="(row, index) in splitting"
              :key="index"
              v-model.number="row.height"
              style="max-width: 100px"
              type="Number"
              step="1"
              @keyup.native.enter="$event.target.blur()"
              @focus="selectAll"
              @focusout="
                checkSegment({ column: index, row: 0, check_type: 'vertical' })
              "
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-alert v-show="expand" dense border="left" type="warning">
          {{ $t("dimension.alerts.segmentError") }}
        </v-alert>
      </v-expand-transition>

      <!-- @todo -> Custom Handle Position
                  <v-row v-for="(row, index) in splitting" :key="index">
                    <v-col
                      v-if="!row.section_codes.join('').includes('F')"
                      cols="12"
                      md="6"
                      class="d-flex"
                    >
                      <v-switch
                        v-model="row.custom_handle_position_height"
                        class="mr-2"
                        :label="$t('dimension.customHandlePositionHeight')"
                        @change="
                          processHandlePositionHeight(
                            row.custom_handle_position_height,
                            index
                          )
                        "
                      ></v-switch>
                      <v-text-field
                        v-if="!row.section_codes.join('').includes('F')"
                        v-model.number="row.handle_position_height"
                        style="max-width: 100px"
                        type="Number"
                        step="1"
                        :label="$t('dimension.handlePositionHeight')"
                        @focusout="$root.$emit('sectionChanged')"
                        :disabled="!row.custom_handle_position_height"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  -->
    </div>
  </div>
</template>
<script>
import SectionHeader from "@/components/SectionHeader";
import Window from "@/components/Window";
import { mainMixin } from "@/mixins/mainMixin";
//import BackendService from "@/services/BackendService";
import axios from "axios";

export default {
  name: "dimensions",
  components: {
    SectionHeader,
    Window
  },
  mixins: [mainMixin],
  data: () => ({
    dimension: {},
    dimensionErrors: {},
    expand: false,
    svgMaxHeight: "400",
    svgMaxWidth: "800"
  }),
  computed: {
    dimensions() {
      return this.$store.state.productData.dimensions;
    },
    get_opening() {
      return this.$store.state.configuration.customerConfig.openings;
    },
    total_width() {
      return `${this.dimensions?.min_max_values?.min_width} - ${this.dimensions?.min_max_values?.max_width}`;
    },
    total_height() {
      return `${this.dimensions?.min_max_values?.min_height} - ${this.dimensions?.min_max_values?.max_height}`;
    },
    image() {
      return this.dimensions?.min_max_values?.image;
    },
    processedOpeningCode() {
      return this.dimensions?.min_max_values?.opening_code
        ?.split("_")
        .map((c) => c.split("-"));
    },
    showSplittingColumns() {
      return this.processedOpeningCode?.length > 1;
    },
    showSplittingRows() {
      return this.processedOpeningCode
        ?.map((c) => c?.length)
        ?.some((c) => c > 1);
    },
    ifNotHST() {
      const openingCode = this.$store.state.configuration.customerConfig.openings.opening_code;
      return !["HSTR-HSTF", "HSTF-HSTL", "HSTF-HSTR-HSTF", "HSTF-HSTL-HSTF", "HSTF-HSTL-HSTR-HSTF"].includes(openingCode);
    },
    totalWidth: {
      get() {
        return this.$store.state.configuration.customerConfig.dimensions
          .total_width;
      },
      set(value) {
        const payload = {
          key: "total_width",
          value
        };
        this.$store.dispatch("configuration/setDimensionsData", payload);
      }
    },
    totalHeight: {
      get() {
        return this.$store.state.configuration.customerConfig.dimensions
          .total_height;
      },
      set(value) {
        const payload = {
          key: "total_height",
          value
        };
        this.$store.dispatch("configuration/setDimensionsData", payload);
      }
    },
    splitting: {
      get() {
        return this.$store.state.configuration.customerConfig.dimensions
          .splitting;
      },
      set(value) {
        const payload = {
          key: "splitting",
          value
        };
        this.$store.dispatch("configuration/setDimensionsData", payload);
      }
    },
    minWidth() {
      return this.dimensions?.min_max_values?.min_width;
    },
    maxWidth() {
      return Number(this.dimensions?.min_max_values?.max_width);
    },
    minHeight() {
      return this.dimensions?.min_max_values?.min_height;
    },
    maxHeight() {
      return this.dimensions?.min_max_values?.max_height;
    },
    splittingRows() {
      return this.dimensions?.splitting?.rows;
    },
    splittingColumns() {
      return this.dimensions?.splitting?.columns;
    },
    setIdAutomaticReloading() {
      if (this.$store.getters["setup/getReloadProduct"] === "1") {
        return "reloadConfigurationData";
      } else {
        return "";
      }
    }
  },
  methods: {
    selectAll(event) {
      const inputElement = event.target;
      if (inputElement && inputElement.select) {
        inputElement.select();
      }
    },
    async totalWidthCheck(payload) {
      let splitting_array = {
        model_id: this.$store.state.configuration.customerConfig.profiles.id,
        current_row: payload.row,
        current_column: payload.column,
        splitting_array: this.splitting,
        //check_type: payload.check_type,
        check_type: "horizontal"
      };

      let res = await axios.post(
        process.env.VUE_APP_API_URL + `check-all-segments`,
        splitting_array,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": process.env.VUE_APP_API_APP_KEY
          }
        }
      );

      if (res.data.i_error === true) {
        this.showAlert();
        window.setTimeout(this.hideAlert, 10000);
        await this.$store.dispatch("configuration/resetDimensionsData", {
          key: "total_width",
          value:
          this.$store.state.productData.dimensions.min_max_values
            .default_width
        });
      }

      if (this.totalWidth > this.maxWidth) {
        this.totalWidth = this.maxWidth;
      } else if (this.totalWidth < this.minWidth) {
        this.totalWidth = this.minWidth;
      }
      this.$set(this.dimensionErrors, "totalWidth", "");

      this.$root.$emit("sectionChanged");
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );
    },
    async totalHeightCheck(payload) {
      let splitting_array = {
        model_id: this.$store.state.configuration.customerConfig.profiles.id,
        current_row: payload.row,
        current_column: payload.column,
        splitting_array: this.splitting,
        check_type: "horizontal"
      };

      let res = await axios.post(
        process.env.VUE_APP_API_URL + `check-all-segments`,
        splitting_array,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": process.env.VUE_APP_API_APP_KEY
          }
        }
      );

      if (res.data.i_error === true) {
        this.showAlert();
        window.setTimeout(this.hideAlert, 10000);
        await this.$store.dispatch("configuration/resetDimensionsData", {
          key: "total_height",
          value:
          this.$store.state.productData.dimensions.min_max_values
            .default_height
        });
      }

      if (this.totalHeight > this.maxHeight) {
        this.totalHeight = this.maxHeight;
      } else if (this.totalHeight < this.minHeight) {
        this.totalHeight = this.minHeight;
      }
      this.$root.$emit("sectionChanged");
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );
    },
    minMaxWidthCheck() {
      let message;
      if (this.totalWidth < this.minWidth) {
        message = this.$i18n.t("dimension.alerts.minWidthText", {
          width: this.minWidth
        });
      } else if (this.totalWidth > this.maxWidth) {
        message = this.$i18n.t("dimension.alerts.maxWidthText", {
          width: this.maxWidth
        });
      } else {
        message = "";
      }
      this.$set(this.dimensionErrors, "totalWidth", message);

      return true;
    },
    minMaxHeightCheck() {
      let message;
      if (this.totalHeight < this.minHeight) {
        message = this.$i18n.t("dimension.alerts.minHeightText", {
          Height: this.minHeight
        });
      } else if (this.totalHeight > this.maxHeight) {
        message = this.$i18n.t("dimension.alerts.maxHeightText", {
          Height: this.maxHeight
        });
      } else {
        message = "";
      }
      this.$set(this.dimensionErrors, "totalHeight", message);

      return true;
    },
    async checkSegment(payload) {
      let splitting_array = {
        model_id: this.$store.state.configuration.customerConfig.profiles.id,
        current_row: payload.row,
        current_column: payload.column,
        splitting_array: this.splitting,
        check_type: payload.check_type
      };

      let res = await axios.post(
        process.env.VUE_APP_API_URL + `check-all-segments`,
        splitting_array,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": process.env.VUE_APP_API_APP_KEY
          }
        }
      );
      await this.$store.dispatch(
        "configuration/setSplittingDataFromApi",
        res.data.a_data
      );

      //console.log(res.data.a_data[0]);
      //console.log(Object.entries(res.data.a_data[0].validated_widths));

      if (res.data.i_error === true) {
        this.showAlert();
        window.setTimeout(this.hideAlert, 10000);
        await this.$store.dispatch(
          "configuration/resetDimensionsSplittingData"
        );
        this.$root.$emit("sectionChanged");
      } else {
        this.$root.$emit("sectionChanged");
      }
    },
    processHandlePositionHeight(value, index) {
      if (!value) {
        this.splitting[index].handle_position_height = undefined;
        this.$root.$emit("sectionChanged");
      }
    },
    showAlert() {
      this.expand = true;
    },
    hideAlert() {
      this.expand = false;
    },
    reloadAutomaticallyConfigurationData() {
      this.$store.dispatch("configuration/setConfigurationFromExistProduct");
      this.$store.dispatch("setup/setReloadProductToNull");
      this.$store.dispatch("setup/setOverlayLoader2", false);
      this.$root.$emit("sectionChanged");
    },
    setErrorDialogWrongConfiguratorVersion() {
      this.$store.dispatch(
        "setup/setErrorDialogTitleText",
        this.$t("errors.reloadingProduct_errorDialogTitleText")
      );
      this.$store.dispatch(
        "setup/setErrorDialogBodyText",
        this.$t("errors.reloadingProduct_errorDialogBodyText")
      );
      this.$store.dispatch(
        "setup/setErrorDialogButtonText",
        this.$t("errors.reloadingProduct_errorDialogButtonText")
      );
      this.$store.dispatch("setup/setErrorDialogShow", true);
    },
    updateSvgDimensions() {
      if (window.innerWidth <= 480) {         // Mobile Geräte
        this.svgMaxHeight = "200";
        this.svgMaxWidth = "300";
      } else if (window.innerWidth <= 768) {  // Tablets
        this.svgMaxHeight = "300";
        this.svgMaxWidth = "600";
      } else {                                // Desktops
        this.svgMaxHeight = "400";
        this.svgMaxWidth = "800";
      }
    }
  },
  watch: {
    // workaround for bug in validation. clear any validation error message
    // when the min / max width / height changes, e.g. another opening is selected
    "dimensions.min_max_values": {
      handler() {
        this.$set(this, "dimensionErrors", {});
      },
      splitting: {
        deep: true,
        handler() {
          this.$store.dispatch(
            "configuration/setDimensionsData",
            this.splitting
          );
        }
      }
    }
  },
  created() {
    this.$set(this, "dimension", this.splitting);
  },
  mounted() {
    // run automatically for reload Configuration Data
    //let divisionId = document.getElementById("reloadConfigurationData");
    if (this.$store.getters["setup/getReloadProduct"] == 1) {
      setTimeout(() => {
        let setupJson = JSON.parse(this.$store.getters["setup/getSetupJson"]);
        if (
          setupJson.apiConfiguratorVersion ===
          this.$store.getters["setup/getApiConfiguratorVersion"]
        ) {
          this.reloadAutomaticallyConfigurationData();
        } else {
          this.$store.dispatch("setup/setOverlayLoader2", false);
          this.setErrorDialogWrongConfiguratorVersion();
        }
      }, "2500");
    } else {
      setTimeout(() => {
        this.$store.dispatch("setup/setOverlayLoader2", false);
      }, "1800");
    }

    const checkAndSetDimensions = () => {
      const quickWidth = this.$store.getters["setup/getQuickWidth"];
      const quickHeight = this.$store.getters["setup/getQuickHeight"];
      const currentTotalWidth = this.$store.state.configuration.customerConfig.dimensions.total_width;

      if (currentTotalWidth && currentTotalWidth > 0 && quickWidth !== '' && quickWidth > 0 && quickHeight !== '' && quickHeight > 0) {
        this.$store.dispatch("configuration/setDimensionsData", { key: "total_width", value: quickWidth });
        this.$store.dispatch("configuration/setDimensionsData", { key: "total_height", value: quickHeight });
        clearInterval(intervalId);  // Stop checking once the condition is met
      }
    };

    const intervalId = setInterval(checkAndSetDimensions, 50);

    this.updateSvgDimensions();
    window.addEventListener("resize", this.updateSvgDimensions);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateSvgDimensions);
  }
};
</script>
<style>
/*get rid of the ugly number spinners*/
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (max-width: 480px) {
  .window-container {
    max-height: 200px;
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .window-container {
    max-height: 300px;
    max-width: 600px;
  }
}

@media (min-width: 769px) {
  .window-container {
    max-height: 400px;
    max-width: 800px;
  }
}

</style>
